import React from 'react'
import CookieConsent from 'react-cookie-consent';
import Footer from '../components/Footer';
import AufstellerAR from '../components/Hero3DSection/ARJanaufsteller2';
import WallJan from '../components/Hero3DSection/artjan';
import WallJan2 from '../components/Hero3DSection/artjan2';
import WallJan3 from '../components/Hero3DSection/artjan3';
import WallJan4 from '../components/Hero3DSection/artjan4';
import WallJan5 from '../components/Hero3DSection/artjan5';
import WallJan6 from '../components/Hero3DSection/artjan6';
import WallJan7 from '../components/Hero3DSection/artjan7';
import ScrollToTop from '../components/ScrollToTop';
import { Icon2 } from '../components/Sidebar/SidebarElements';
import SignIn from '../components/Signin'

const JanPage = () => {
    return (
        <>
        <ScrollToTop/>
        <AufstellerAR/>
        <Icon2>Jan Hagmann 2021</Icon2>
         <WallJan/>
         <WallJan2/> 
         {/*<WallJan3/> */}            
         <WallJan5/>        
         <Icon2>Jan Hagmann 2020</Icon2>

         <WallJan6/>
         <WallJan4/>
        {/*  <WallJan7/> */}
            <CookieConsent debug={true}
        buttonText="OK"
        buttonStyle={{ background: "#3a54ff", fontSize: "20px", borderRadius: "15px", color: "white" }}

      >

        Diese Seite benutzt Cookies um die Funktion der Website zu gewährleisten. Durch die Nutzung der Website stimmen Sie der Verwendung von Cookies zu. Hier erfahren sie mehr über unseren <a style={{ color: "#3a54ff" }} href="/Datenschutz">Datenschutz</a>.
      </CookieConsent>
            <Footer/>
        </>
    )
}

export default JanPage;