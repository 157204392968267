
import React, { useState, useRef } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import ImpressDaten from '../components/ImpressumDaten'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/index';

const ImpressumPage = () =>{

    const [isOpen, setIsOpen] = useState(false)
  
    const toggle = () => {
      setIsOpen(!isOpen)
    }

    return (
        <>
      <Navbar toggle={toggle} />
             <ScrollToTop/> 
            <ImpressDaten />
            <Footer     />
        </>
    )
}

export default ImpressumPage;