import React from 'react'
import {
    InfoContainerK,InfoContainerN,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap, Schrift3,Schrift4,SchriftContainer4,Schrift5,Schrift6,
    Img2, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB,Schrift2, SchriftContainer2, Schrift, SchriftContainer, Column2N, InfoContainerNB, InfoContainerNBP, Schrift8, Schrift9, D1ColumnLA, Schrift7
} from './Hero3DElement';
import logo from './galerielaing.png';
import profilhack from './klaushack1001.jpg';
import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";

import ARN from '../ARSection/arnuhr';
import { NavLogo } from '../Navbar/NavbarElements';
import HackDame from '../ARSection/ARHackDame';
import HackDameklein from '../ARSection/ARKleineDame';
import hackdameklein from '../ARSection/ARKleineDame';
import HackKreuz from '../ARSection/ARKreuz';

const ARHack = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,toggleHome }) => {
    return (
        <>
  {/*       <InfoContainerNB >
        <NavLogo > <Img2 class="flex" src={logo} alt="Logo" /> </NavLogo>
        </InfoContainerNB> */}
     {/*    <SchriftContainer4>
                    
                        <Schrift6 >Klaus Hack</Schrift6>   
    
        <InfoContainerNBP >
        <NavLogo > <Img2 class="flex" src={profilhack} alt="Logo" /> </NavLogo>
        
        </InfoContainerNBP>




        <Schrift8 >Skulpturen – Reliefs – Holzschnitte

</Schrift8>    

                        </SchriftContainer4> */}
         













            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}



                        <Column2N>
                        
                        <SchriftContainer>
                        <Schrift7 >Augmented Reality Anleitung </Schrift7> 
 <Schrift3 >Mit dem Handy je nach Objekt die Wand oder den Boden der Wahl anvisieren, den "View in AR" Button betätigen,
  mit dem Handy die Fläche abscannen. Sobald das Objekt "aufspringt" und ein grauer Rahmen um das Objekt erscheint, 
  kann man es an die gewünschte Stelle positionieren.</Schrift3>                       
 </SchriftContainer>


                   
   <HackDameklein/>

                        <SchriftContainer2>
                 {/*            <Schrift2>Künstler: Klaus Hack</Schrift2> */}
                 <Schrift2>KLAUS HACK</Schrift2>    
                            <Schrift2>Figur, 2009</Schrift2>                          
                        <Schrift2 >Eiche, weiß gefasst</Schrift2>  
                        <Schrift2>69 x 28 x 23,5 cm</Schrift2>                      
                        </SchriftContainer2>
                        <SchriftContainer2>
                                            
                        </SchriftContainer2>
                     



               
                        <D1ColumnLA>
           <a href="https://www.galerie-laing.com/kontakt/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingko">Kontakt</a> 
</D1ColumnLA>
<InfoContainerNB >
        <NavLogo /* to="/" onClick={toggleHome} */> <Img2 class="flex" src={logo} alt="Logo" /> </NavLogo>
</InfoContainerNB>



                        </Column2N>
                

            </InfoContainerN>
        </>
    );
};

export default ARHack;


















