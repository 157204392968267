import React from "react";
import "@google/model-viewer/dist/model-viewer";
import "./styles.css";


function ARJAN2() {
    return (


<model-viewer src="art2.glb"  ar ios-src="art2.usdz"
 ar ar-placement="wall"
 exposure="1.12" 
 ar-modes="webxr scene-viewer quick-look"
  camera-controls alt="Jan Hagmann"
  max-camera-orbit="auto 98deg auto"
  min-camera-orbit="auto 50deg auto"
  camera-orbit="0deg 75deg 2.5m ">
 <button slot="ar-button" id="ar-button">  View in AR</button>
 </model-viewer>


    );
}

export default ARJAN2;



