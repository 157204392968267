import React, { useState } from "react";
import "@google/model-viewer/dist/model-viewer";
import "./styles.css";
import { element } from "prop-types";
import Kopf1000 from "../Hero3DSection/HorstAntes";

/* function AR2() {
    return (
        <div className="AR1">

                <model-viewer
                    src="VaseGeometr.glb"
                    alt="A 3D model of an astronaut"
                    shadow-intensity="5"
                    auto-rotate
                    ar   >
                </model-viewer>
          
        </div>
    );
}

export default AR2; */


function AR2() {

 /*     const [appState, changeState] = useState({
        activeObject: null,
        objects: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]

    });

    function toggleActiv(index) {
        changeState({ ...appState, activeObject: appState.objects[index] });
    } 
   
        function toggleActiveStyles(index) {
            if (appState.objects[index] === appState.activeObject) {
                return "box active1";
            } else {
                return "box inactive1";
            }
        }
     

        var modelViewer = document.querySelector("model-viewer"); 
  var modelViewer = document.getElementById('3d'); 
 */



    return (

<>
      
            <model-viewer id="3d"
                src="lampe2.glb" ar ios-src="vasegeometr.usdz"
                alt="Atle Vase"
                exposure="0.32"
                shadow-intensity="4"
                shadow-softness="1"
                auto-rotate
                ar camera-controls
                max-camera-orbit="auto 91deg auto"
                min-camera-orbit="auto 50deg auto"
                camera-orbit="45deg 75deg 1.0m "
            ><button slot="ar-button" id="ar-button">
                    View in AR
                </button>
                <button slot="hotspot-hand" data-position="-0.54 0.93 0.1" data-normal="-0.73 0.05 0.69">
                    <div id="annotation">Antic Potterie Geometric Period 700 B.C. Greece</div>
                </button>

            </model-viewer>

{/*             <div class="slider">
                <div class="slides">

                    <button class="slide oel" onClick={() => { modelViewer.src = "OellampeAntik.glb"; }}></button>
                    <button class="slide art" onClick={() => { modelViewer.src = "ArtDecoVogel.glb"; }} ></button>
                    <button class="slide geo" onClick={() => { modelViewer.src = "VaseGeometr.glb" }}></button>

                </div>
            </div>  */}

</>




    );
}


export default AR2;


{/*              <button class="slide" onClick={() => { modelViewer.src = "Kopf1000.glb" }} > </button> */ }


            {/*          <div className="ModelButton">

                <div class="slider">
                    <div class="slides">

                        {appState.objects.map((elements, index,src) => (
                            <div key={index} className={toggleActiveStyles(index)} onClick={() => { toggleActiv(index);modelViewer.src="Kopf1000.glb"}}></div>
                        ))}
                    </div>
                </div>
            </div> */}


 {/*            <div class="slider">
                <div class="slides">

                    <button class="slide oel" onClick={() => { modelViewer.src = "OellampeAntik.glb"; }}></button>
                    <button class="slide art" onClick={() => { modelViewer.src = "ArtDecoVogel.glb"; }} ></button>
                    <button class="slide geo" onClick={() => { modelViewer.src = "VaseGeometr.glb" }}></button>

                </div>
            </div> */}
       