import React, { useState, useRef } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo, homeObjThree, Three1Data, Three2Data, Three3Data, Three5Data, Three6Data, Three7Data, ContactFormData, Kopf1000D, WallData, ART, Dargon, ARTJAN } from '../components/InfoSection/Data';
import Services from '../components/Services';
import Footer from '../components/Footer/index';
import AR1 from '../components/ARSection/ARSection';
import ThreeDSection from '../components/Hero3DSection';
import AR2 from '../components/ARSection/ARSection2';
import ThreeSection from '../components/ThreeDSection/ThreeDSection';
import Three1 from '../components/ThreeDSection/Three1';
import { useIntersection } from "react-use";
import gsap from "gsap";
import Three3 from '../components/ThreeDSection/Three3';
import Three2 from '../components/ThreeDSection/Three2';
import Three2N from '../components/ThreeDSection/Three2N';
import CookieConsent from "react-cookie-consent";



import { Html, useProgress, useGLTF } from "drei";
import { a, useTransition } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import AppScroll from '../components/Scroll/Scroll';
import Three4 from '../components/ThreeDSection/Three4';
import Three5 from '../components/ThreeDSection/Three5';
import Three6 from '../components/ThreeDSection/Three6';
import Three7 from '../components/ThreeDSection/Three7';
import ContactForm from '../components/form/form';
import Kopf1000 from '../components/Hero3DSection/HorstAntes';
import WallSchwan from '../components/Hero3DSection/WallSchwan';
import ArtDeco from '../components/Hero3DSection/ArtDeco';
import Contact from '../components/form/contact';
import DragonSection from '../components/Hero3DSection/DragonHero';
import WallJan from '../components/Hero3DSection/artjan';
import WallJan2 from '../components/Hero3DSection/artjan2';
import b360 from '../components/Hero3DSection/360';
import PannellumReact from '../components/Hero3DSection/360';
import Bild360 from '../components/Hero3DSection/360';



//--------------LOADER------------------

function Loader() {
  const { active, progress } = useProgress();
  const transition = useTransition(active, {
    from: { opacity: 1, progress: 0 },
    leave: { opacity: 0 },
    update: { progress },
  });
  return transition(
    ({ progress, opacity }, active) =>
      active && (
        <a.div className='loading' style={{ opacity }}>
          <div className='loading-bar-container'>
            <a.div className='loading-bar' style={{ width: progress }}></a.div>
          </div>
        </a.div>
      )
  );
}




const Home = () => {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>

      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <Three1  {...Three1Data} />

      <ThreeDSection  {...Three7Data} />

      <WallSchwan {...WallData} />
      <ArtDeco  {...ART} />

{/*       <Bild360 />
 */}      {/*  < ContactForm  {...ContactFormData} /> */}

{/*        < WallJan {...ARTJAN} />
       < WallJan2 {...WallData} /> */}
      <Contact  {...ContactFormData} />

 {/*      < DragonSection {...Dargon} /> */}


      {/* <Three5  {...Three5Data} />  */}

      {/*       <Kopf1000  {...Kopf1000D} /> */}



      {/*       <Three4  {...Three3Data} />   */}
      {/* <Three6  {...Three6Data} /> */}

      {/*   <Three7  {...Three7Data} />   */}

      {/*   <AR2 /> */}






      {/*     <Three2N {...Three3Data}/> */}

      {/*   <Three2  {...Three3Data} /> */}

      {/*      <Three3  {...Three3Data} /> */}

      {/* 
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <Services />
      <InfoSection {...homeObjThree} />
      <AR2 />
 */}
      <Footer />
      <CookieConsent debug={true}
        buttonText="OK"
        buttonStyle={{ background: "#3a54ff", fontSize: "20px", borderRadius: "15px", color: "white" }}

      >

        Diese Seite benutzt Cookies um die Funktion der Website zu gewährleisten. Durch die Nutzung der Website stimmen Sie der Verwendung von Cookies zu. Hier erfahren sie mehr über unseren <a style={{ color: "#3a54ff" }} href="/Datenschutz">Datenschutz</a>.
      </CookieConsent>
    </>
  );
};

export default Home;
