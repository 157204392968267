import React, { useState } from "react";
import "@google/model-viewer/dist/model-viewer";
import "./styles.css";
import { element } from "prop-types";



function Luepertz1() {




    return (

        <>

            <model-viewer id="3d"
                src="luepertz1.glb"  ios-src="luepertz4.usdz"
                ar-scale="fixed"
                alt="Frauenfigur"
                exposure="0.85"
                shadow-intensity="4"
                shadow-softness="1"
                auto-rotate
                ar camera-controls
                max-camera-orbit="auto 91deg auto"
                min-camera-orbit="auto 50deg auto"
                camera-orbit="45deg 75deg 10.5m ">
 <button slot="ar-button" id="ar-button">  View in AR</button>

            </model-viewer>



        </>




    );
}


export default Luepertz1;


