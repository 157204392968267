import styled from "styled-components";
import { Link } from "react-router-dom"

export const D1ColumnL = styled.div`
justify-content: center;
text-align: center;
align-items: center;
/* width:280px;  */
/* grid-area: col1; */
/*  margin-left:80px;
 */background-color: white;
/* 
@media screen and (max-width:1580px) {
  justify-content: center; 
   width:30vw;
 margin-left:0px; 
}*/
`;

export const D1ColumnLA = styled.div`
justify-content: center;
text-align: center;
align-items: center;
/* width:280px;  */
/* grid-area: col1; */
/*  margin-left:80px;
 */
/* 
@media screen and (max-width:1580px) {
  justify-content: center; 
   width:30vw;
 margin-left:0px; 
}*/
`;

export const Schrift = styled.div`
color:white;
font-size: 18px;
/* font-weight: bold; */
padding-top: 2px;
margin-bottom: 2px;
text-align:center;
`;

export const SchriftContainer = styled.div`
/* background-color:red; */
padding-bottom: 2px;
padding-top: 62px;

margin-left:20px;
margin-right:20px;
`;

export const Schrift2 = styled.div`
color:white;
font-size: 24px;
/* font-weight: bold; */
padding-top: 2px;
margin-bottom: 2px;
margin-left:10px;
margin-right:10px;
text-align:center;font-family: Arial, Helvetica, sans-serif ;
`;

export const Schrift3 = styled.div`
color:white;
font-size: 18px;
/* font-weight: bold; */
padding-top: 2px;
margin-bottom: 2px;
margin-left:10px;
margin-right:10px;
text-align:center;
margin-bottom: 100px;
color: grey;font-family: Arial, Helvetica, sans-serif ;
`; 

export const Schrift4 = styled.div`
color:grey;
font-size: 32px;
/* font-weight: bold; */
/* font-style: bold; */
text-align:center;
font-weight:600;
background-color: white;
padding-bottom: 20px;
margin-left:10px;
margin-right:10px;
font-family:  Geometric, sans-serif ;


`;

export const Schrift7 = styled.div`
color:grey;
font-size: 28px;
/* font-weight: bold; */
/* font-style: bold; */
text-align:center;
font-weight:600;
padding-bottom: 20px;

margin-left:10px;
margin-right:10px;
font-family:  Geometric, sans-serif ;


`;
export const Schrift8 = styled.div`
color:grey;
font-size: 18px;
/* font-weight: bold; */
/* font-style: bold; */
text-align:center;
font-weight:600;
padding-bottom: 8px;
padding-top: 8px;
margin-left:10px;
margin-right:10px;
font-family:  Geometric, sans-serif ;
`;


export const Schrift9 = styled.div`
color:white;
font-size: 18px;
/* font-weight: bold; */
padding-top: 48px;
margin-bottom: 2px;
margin-left:10px;
margin-right:10px;
text-align:center;

color: grey;font-family: Arial, Helvetica, sans-serif ;
`; 










export const Schrift6 = styled.div`
color: #202222;
font-size: 42px;
/* font-weight: bold; */
/* font-style: bold; */
text-align:center;
font-weight:600;
background-color: white;
padding-bottom: 20px;
margin-left:10px;
margin-right:10px;
text-decoration: underline #202222;    
font-family:  Geometric, sans-serif ;


`;




export const Schrift5 = styled.div`
color:#202222;
font-size: 42px;
padding-top:28px;
/* font-weight: bold; */
/* font-style: bold; */
text-align:center;
/* margin-left:10px;
margin-right:10px */;
background-color: white;
padding-bottom: 100px;
padding-top: 100px;
font-family: Arial, Helvetica, sans-serif ;
text-decoration: underline;

`;


export const SchriftContainer4 = styled.div`
background-color:white;
padding-top: 155px;
`;



export const SchriftContainer2 = styled.div`
/* background-color:red; */

padding-top: 220px;

`;

export const InfoContainer = styled.div`
color: #fff;
margin-top: 0px;
padding-top: 36px;
background: ${({lightBg}) => (lightBg ? "#f9f9f9" : "#191919")};
/* background: ${({lightBg}) => (lightBg ? "#f9f9f9" : "#010606")};
 */@media screen and (max-width: 1580px) {
  
}`;




export const BLinkLB = styled(Link)`
color: #fff;
text-decoration: none;
margin-top: 0.5rem;
margin-bottom: 0.5rem;
font-size: 14px;
align-items: center;
text-align: center;
justify-content: center;
&:hover {
    color: #3a54ff;
    transition: 0.3s ease-out;
}`;


/* export const BLinkLB = styled(Link)`
color: #fff;
text-decoration: none;
margin-top: 0.5rem;
margin-bottom: 0.5rem;
font-size: 14px;
align-items: center;
text-align: center;
justify-content: center;
&:hover {
    color: #3a54ff;
    transition: 0.3s ease-out;
}`; */




export const InfoContainerK = styled.div`
color: #fff;
margin-top: 0px;
padding-top: 36px;

background: ${({lightBg}) => (lightBg ? "#f9f9f9" : "#191919")};
/* background: ${({lightBg}) => (lightBg ? "#f9f9f9" : "#010606")};
 */@media screen and (max-width: 1580px) {
  

}`;

export const InfoContainerN = styled.div`
color: #fff;
margin-top: 0px;
/* padding-top: 36px; */
background: ${({lightBg}) => (lightBg ? "#f9f9f9" : "#191919")};
/* background: ${({lightBg}) => (lightBg ? "#f9f9f9" : "#010606")};
 */@media screen and (max-width: 1580px) {
  

}`;


export const InfoContainerNB = styled.div`
color: #fff;
margin-top: 0px;
padding-top: 36px;
background: white;
/* padding-left:36vw; */
/* padding-right:36vw; */
padding-bottom:70px;   
    display: flex; 
    justify-content: center; 
    align-items: center;
    padding-right: 30px;
position: center;text-align:center; 
height: 100vh;

`;

export const InfoContainerNBP = styled.div`
color: #fff;
margin-top: 0px;
padding-top: 20px;
background: white;
/* padding-left:36vw; */
/* padding-right:36vw; */
padding-bottom:10px;   
    display: flex; 
    justify-content: center; 
    align-items: center;
    padding-right: 30px;
position: center;text-align:center; 


`;


export const Img2 = styled.img`
width: 280px;
height: 280px;
background:  white;
text-align: center;
position: center;
`;

export const Profilbild1 = styled.img`
width: 280px;
height: 280px;
background:  white;
text-align: center;
position: center;
`;


/* padding: 0px 00px;
margin-top:-4px;
margin-right:8px;
margin-left:-24px; */

/* width: 100vw;
margin: 0 0 10px 0;
padding-right: 0; */



export const InfoWrappper = styled.div`
display: grid;
height: 100vh; 


padding-left:15vw;

padding-right:5vw;
@media screen and (max-width: 1580px) {
    padding-left:20px;
    height: 100%; 
}`;

/* export const InfoWrappper = styled.div`
display: grid;
background-color: #121212;

width: 100vw; 
padding-left:12vw;

padding-right:5vw;
@media screen and (max-width:  1000px) {
 
}`; */

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
padding-left:0px;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 1580px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`};

}`;

export const InfoRowB  = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;

grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 1580px) {
    grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};
    margin-left:10vw;
}`;



/* export const InfoRow = styled.div`
display: grid;


align-items: center;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};
@media screen and (max-width: 1000px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`};

}`; */

export const Column1 = styled.div`
width:100%; 
text-align: left;
max-width:800px; 
grid-area: col1;
padding-right: 9vw;
position: center;
display: flex;
@media screen and (max-width: 1580px) {

}`;

export const Column2 = styled.div`
width:100%; 

max-width:500px; 
grid-area: col2;
text-align: left;
@media screen and (max-width: 1580px) {
 margin-top: 50px;
  /* margin-left:0px; */
}`;

export const Column2K = styled.div`
width:100%; 
height:100%;
grid-area: col2;
/* text-align: left; */
/* background-color: white; */

`;

export const Column2N = styled.div`
width:100%; 
height:100%;
grid-area: col2;
/* text-align: left; */
/*  background-color: white;  */

`;



export const D1Column1 = styled.div`

/* text-align: center; */
/* width:280px;  */
grid-area: col1;
/* margin-left:80px; */
@media screen and (max-width:1580px) {
  justify-content: center;
  width:30vw;
  /* margin-left:0px; */
}`;

export const D1Column2 = styled.div`
text-align: center;
/* width:120px; */
grid-area: col2;

@media screen and (max-width: 1580px) {
 /*    justify-content: center; */
  width:30vw;
  margin-left:20px;
}`;

export const SubWrap = styled.div`
/* background-color:white;
 *//* padding-top:25px; */
width:80vw;
align-items: center;
padding-top: 25px;
/* border-top: 3px solid  #3a54ff; */
/* border-radius: 1.12em; */ 
@media screen and (min-width:1580px) {
    /* margin-top: 10px; */
    
    display: none
}`;

export const BorderWrap = styled.div`
/* background-color:white;
 *//* padding-top:25px; */
width:25vw;  
justify-content: center;
margin-top:18px;
margin-left:35%;
border-bottom: 4px solid  #3a54ff;

align-items: center;
align-content:center;text-align: center;
/* border-top: 3px solid  #3a54ff; */
/* border-radius: 1.12em; */ 
@media screen and (min-width: 1580px) {
    /* margin-top: 10px; */
    
    display: none
}`;




export const TopLineT = styled.p`
color:white;
/* color:#68D446; */
font-size: 28px;
/* line-height: 25px;
 */font-weight: bold;
/* letter-spacing: 1.0px;
text-transform: uppercase;
 */
padding-top: 35px;
 margin-bottom: 8px;
text-align:center;


/* border-right:  3px solid  #3a54ff;
 */@media screen and (max-width: 1580px) {
    font-size: 21px;
 
    margin-left:20px;
}
@media screen and (min-width: 1580px) {
    /* margin-top: 10px; */
     font-size:20px;margin-left:20px;
    display: none
}`;


export const TextWrapper = styled.div`
width: 600px;
max-width: 80vw;
padding-top: 0 ;
padding-bottom: 60px;
`;

export const TopLine = styled.p`
/* color:#FF3B26;*/
/* color: #3a54ff;
 *//* color: #fc5c65; */
font-size: 26px;
line-height: 28px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 16px;
display: none;

@media screen and (max-width: 1580px) {
  
    display: none
}`;





export const Heading = styled.h1`
/* text-shadow: 3px 3px 10px #191919; */
margin-top: 24px;
font-size: 8.5rem;
margin-bottom: 24px;
font-size: 34px;
line-height:1.15;
display: flex;
text-shadow:silver;
font-weight: 800;
color:white;
opacity: 1;

/* text-shadow: 1.2px 1.2px 1.4px #d1d1d1;
 */
@media screen and (max-width: 1580px) {
    font-size: 22px;text-align:left;
margin-left:20px;
}`;




export const HeadingA = styled.h1`
text-shadow: 3px 3px 10px #191919;
margin-top: 24px;
font-size: 8.5rem;
margin-bottom: 24px;
font-size: 38px;
line-height:1.15;
display: flex;
text-shadow:silver;
font-weight: 800;
color:white;
opacity: 1;

/* text-shadow: 1.2px 1.2px 1.4px #d1d1d1;
 */
@media screen and (max-width: 1580px) {
    margin-top: 1px;
    display: none
}`;







export const InfoRowA  = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;

grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 1580px) {
    margin-top: 1px;
    display: none
}`;



export const InfoRowAB  = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;

grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 1580px) {
    grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};
    margin-left:10vw;display: none
}
@media screen and (min-width: 1580px) {
    display: none
}
`;

export const Subtitle = styled.p`

margin-bottom: 35px;
font-size: 22px;
line-height: 26px;
/* color: ${({ DdarkText }) => (DdarkText ? "#010606" : "#999999")}; */

color:white;
color:#ababab;
@media screen and (max-width: 1580px) {
 align-items:center;
 text-align:left;
 font-size: 19px;
 width:80vw;margin-left:20px;
 color:#999999;
}`;

export const BtnWrap = styled.div`
display: flex;
justify-content: flex-start;
`;

export const ImgWrap = styled.div`
max-width: 555px;
height: 100vh;align-items:left;
`;

export const Img = styled.img`
width: 100vw;
margin: 0 0 10px 0;
padding-right: 0;
`;


export const ChangeModelTrue = styled.div`

    
`;
export const ChangeModelFalse = styled.div`

    display: none
`;




