import React from 'react'
import {
    InfoContainerK,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB,Schrift, SchriftContainer
} from './Hero3DElement';


import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";
import ARKopf1000 from '../ARSection/ARKopf1000';
import ARWall1 from '../ARSection/ARWall';
import artj1 from '../ARSection/arjan1';
import ARJAN1 from '../ARSection/arjan1';
import ARJAN2 from '../ARSection/arjan2';
import ARJAN3 from '../ARSection/arjan3';
import ARJAN6 from '../ARSection/arjan6';

const WallJan6 = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2 }) => {
    return (
        <>
            <InfoContainerK lightBg={lightBg} id={id}>

            <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading>

                        <Column2K>

                            <ARJAN6/>
                            <SchriftContainer>
                            <Schrift>Titel: still strong</Schrift>
                        <Schrift >Erstellungsjahr: 2020</Schrift>                       
                        <Schrift>Maße: 140 cm x 140 cm x 1.7 cm</Schrift>
                        <Schrift>Material: Acryl, ink spraypaint on mdf wood</Schrift>
                        </SchriftContainer>
                               </Column2K>
                
            </InfoContainerK>
        </>
    );
};

export default WallJan6;
