import React, {useRef, useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, Img } from "./NavbarElements";
import { IconContext } from 'react-icons/lib'
import { animateScroll as scroll} from "react-scroll";
import logo from './logo.png';
import { useIntersection } from "react-use";
import gsap from "gsap";
import { useSpring, animated } from 'react-spring'
import "./nb.css"





const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if (window.scrollY >= 65) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", changeNav);
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };


    const [ready, setReady] = useState(false)
    const mouse = useRef([0, 0])


    const sectionRef = useRef(null);

    const intersection = useIntersection(sectionRef, {
        root: null,
        rootMargin: "0px",
        threshold: 0.8,
    });


    const fadeIn3 = element => {
        gsap.to(element, 1, {
            opacity: 0,
       
            ease: "power4.out",
            stagger: {
                amount: 0.3
            }
        })
    };

    const fadeOut3 = element => {
        gsap.to(element, 1.2, {
            opacity: 1,
           
            ease: "power4.out",

        });
    };

    intersection && intersection.intersectionRatio < 0.8
        ? fadeOut3(".fadeOut3")
        : fadeIn3(".fadeOut3"); //reached so animate

    const props1 = useSpring({
      
        to: { opacity: 1,width: 5000  },
        from: { opacity: 0, width: 5000 },
        delay:3500, duration: 5000,
    })


    return (
        <>
              <IconContext.Provider value={{ color: "#fff" }}>
                    <Nav   >
{/*                 <Nav scrollNav={scrollNav}>
 */}       <animated.div  style={props1}>               <NavbarContainer>
                        <NavLogo to="/" onClick={toggleHome}> <Img src={logo} alt="Logo" /> Scivis3D
                       </NavLogo>
                        <MobileIcon onClick={toggle} >
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                        <NavItem>
                            <NavLinks to="Los gehts"    smooth={true} duration={500} spy={true} exact="true" offset={0} >Los gehts</NavLinks>
                            </NavItem>
                        <NavItem>
                            <NavLinks to="Vorteile"    smooth={true} duration={500} spy={true} exact="true" offset={0} >Die Vorteile</NavLinks>
                            </NavItem>
                            <NavItem>
                            <NavLinks to="Beispiel"    smooth={true} duration={500} spy={true} exact="true" offset={5} >Beispiel</NavLinks>
                            </NavItem>
                            <NavItem>
                                {/* //////////////////////////////////////          offset={-80}        /////////////////////////////////////// */}
                                <NavLinks to="Lösungen" smooth={true} duration={500} spy={true} exact="true" offset={-120} >Lösungen</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="scan" smooth={true} duration={500} spy={true} exact="true" offset={-120} >3D-Scan</NavLinks>
                            </NavItem>
                         {/*    <NavItem>
                                <NavLinks to="vr"  smooth={true} duration={500} spy={true} exact="true" offset={-120} >NFT Casting</NavLinks>
                            </NavItem> */}
                            <NavItem>
                                <NavLinks to="ar"  smooth={true} duration={500} spy={true} exact="true" offset={-120} >Augmented Reality</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="vr"  smooth={true} duration={500} spy={true} exact="true" offset={-120} >Virtuel Reality</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="vr"  smooth={true} duration={500} spy={true} exact="true" offset={-120} >360 Grad</NavLinks>
                            </NavItem>

                            <NavItem>                        
                                <NavLinks to="Service" smooth={true} duration={500} spy={true} exact="true" offset={-120} >Service</NavLinks>
                            </NavItem>

                            <NavItem>
                                <NavLinks to="Kontakt" smooth={true} duration={500} spy={true} exact="true"offset={0} >Kontakt</NavLinks>
                            </NavItem>
                        </NavMenu>
                        <NavBtn to ="/signin">
                       
                        <a class="neon-buttonNav">Sign In</a>
{/*                             <NavBtnLink to="/signin">Sign In</NavBtnLink>
 */}                        </NavBtn>
                    </NavbarContainer></animated.div>
                </Nav>
            </IconContext.Provider>
        </>
    );
};

export default Navbar;
