import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import { FirstPersonControls } from "three/examples/jsm/controls/FirstPersonControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import { FlyControls } from "three/examples/jsm/controls/FlyControls.js";






class ThreeDMouse extends Component {

  ////////////////////////  MAIN FUNCTION //////////////////////////////

  componentDidMount() {

    ////////////////////////  Scene  //////////////////////////////

    var scene = new THREE.Scene()
    {
      const color = 0xFFFFFF;  // white
      const near = 10;
      const far = 50;
      // scene.fog = new THREE.Fog(color, near, far);
    }
    ;
    scene.name = "Scene1";


    ////////////////////////  LOADER  //////////////////////////////

    let loader = new GLTFLoader();
    loader.load("./VaseGeometr.glb", function (gltf) {
      gltf.scene.translateZ(0);
      gltf.scene.translateY(-0.5);
      gltf.scene.translateX(-0.8);
      gltf.scene.scale.set(1, 1, 1);
      gltf.scene.castShadow = true;
      gltf.scene.receiveShadow = true;
      gltf.castShadow = true;
      gltf.receiveShadow = true;




      scene.add(gltf.scene);
      renderer.render(scene, camera);
    });


    /////////////ANIMATE////////////

    
    document.addEventListener("mousemove", onDocumentMouseMove)

    let mouseX = 0
    let mouseY = 0
    let targetX = 0
    let targetY = 0

    const windowX = window.innerWidth / 2;
    const windowY = window.innerHeight / 2;

    function onDocumentMouseMove(event) {
      mouseX = (event.clientX - windowX)
      mouseY = (event.clientY - windowY)

    }


    /*
      const boden = new THREE.PlaneGeometry( 20, 20, 1 );
    
      boden.rotateX(300);
      boden.rotateY(0);
      boden.rotateZ(0);
      boden.translateZ =0;
      boden.translateY =0;
      boden.translateX =200;
      const materialboden= new THREE.MeshPhysicalMaterial( {color: grey, side: THREE.DoubleSide} );
    
      boden.castShadow = true; //default is false
      boden.receiveShadow = true; 
    
      const plane = new THREE.Mesh( boden, materialboden );
      plane.position.set(0,-1.221,-0);
    
    
      plane.castShadow = true; 
      plane.receiveShadow = true; 
      scene.add( plane );
    */

    ////////////////////////  LIGHT  //////////////////////////////

    var ambient = new THREE.AmbientLight(0x404040, 1.0);
    //scene.add( ambient );

    var spot = new THREE.SpotLight(0xFFFFFF, 0.7);
    spot.position.set(-50, 50, 50);
    spot.castShadow = true;
    spot.shadow.bias = -0.0001;
    spot.shadow.mapSize.width = 1024 * 4;
    spot.shadow.mapSize.height = 1024 * 4;
    scene.add(spot);

    var spot2 = new THREE.SpotLight(0xffffff, 50.7);
    spot2.position.set(-5, 5, 5);
    spot2.rotation.set(0, 0, 0);
    // spot.position.y(10);
    // spot.position.szet(7.5);


    spot2.castShadow = true;
    spot2.shadow.bias = 0.00001;
    spot2.shadow.mapSize.width = 1024 * 4;
    spot2.shadow.mapSize.height = 1024 * 4;
    scene.add(spot2);




    var hemiLight = new THREE.HemisphereLight(0xFFFFFF, 0xFFFFFF, 2);

    hemiLight.castShadow = true;



    const ambientLight = new THREE.HemisphereLight(0xddeeff, 0x0f0e0d, 2);
    ambientLight.castShadow = true;

    const mainLight = new THREE.DirectionalLight(0xffffff, 2);
    mainLight.position.set(-2, 10, 2);
    mainLight.castShadow = true;
    mainLight.shadow.bias = -0.0001;
    mainLight.shadow.mapSize.width = 1024 * 4;
    mainLight.shadow.mapSize.height = 1024 * 4;

    // scene.add(hemiLight);
    // scene.add(ambientLight);
    // scene.add(mainLight);

    ////////////////////////  Add Objects  //////////////////////////////



    // const geometry = new THREE.SphereGeometry
    const geometry = new THREE.TorusGeometry(.7, .2, 16, 100);

    const material = new THREE.MeshBasicMaterial();

    material.color = new THREE.Color(0xffBB00);


    const torus = new THREE.Mesh(geometry, material)

    torus.scale.set(0.2, 0.2, 0.2);
    torus.position.x = 1;
    torus.position.y = 1;
    torus.position.z = 1;



    // scene.add(torus);




    ////////////////////////  CAMERA  //////////////////////////////

    var camera = new THREE.PerspectiveCamera(7, window.innerWidth / window.innerHeight, 0.01, 100);
    camera.position.z = 14.2;
    camera.position.y = 1.3;
    camera.position.x = 10.3;


    ////////////////////////  RENDERER  //////////////////////////////

    // var renderer = new THREE.WebGLRenderer({antialias:true} );
    var renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.antialias = true;

    // renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMapping = THREE.CineonToneMapping;

    renderer.shadowMap = THREE.VSMShadowMap;

    renderer.toneMappingExposure = 0.88;


    renderer.info.autoReset = false;

    renderer.gammaFactor = 0.0005;
    renderer.gammaInput = true;
    renderer.gammaOutput = true;


    renderer.physicallyCorrectLights = true;


    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth * 1, window.innerHeight * 1);
    //renderer.setClearColor("#363636,0");
    // renderer.getClearAlpha(1) ;
    renderer.setClearColor(0x000000, 0);


    this.mount.appendChild(renderer.domElement);

    ////////////////////////  Controls  //////////////////////////////

    var controls = new OrbitControls(camera, renderer.domElement);
    //var controls = new FlyControls(scene, renderer.domElement);

    update(renderer, scene, camera, controls);
    // update(renderer, scene, camera,controls );
    return scene;




    ////////////////////////  UPDATE  //////////////////////////////



    function update(renderer, scene, camera, controls) {
      renderer.render(scene, camera);


      var scene1 = scene.getObjectByName("Scene1");
      //scene1.children[0].rotation.y +=0.002;
      scene1.rotation.y += 0.004;





      scene.traverse(function (child) {
        //child.position.x += 0.001;
      });

      controls.update();

      requestAnimationFrame(function () {
        update(renderer, scene, camera, controls);
      });










      document.addEventListener( 'mousemove', onDocumentMouseMove );



      let mouseX = 0, mouseY = 0;

      let windowHalfX = window.innerWidth / 2;
      let windowHalfY = window.innerHeight / 2;

      function onDocumentMouseMove( event ) {

        mouseX = ( event.clientX - windowHalfX );
        mouseY = ( event.clientY - windowHalfY );

    }
    
   




      //document.body.appendChild( renderer.domElement );
      //use ref as a mount point of the Three.js scene instead of the document.body


      ////////////////////////  Generate Objects  //////////////////////////////

      //var geometry = new THREE.BoxGeometry( 1, 1, 1 );
      //var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
      //var cube = new THREE.Mesh( geometry, material );
      //scene.add( cube );


      ////////////////////////  Animire Objects  //////////////////////////////


      //  var animate = function () {
      //    requestAnimationFrame( animate );
      //    cube.rotation.x += 0.01;
      //    cube.rotation.y += 0.01;
      //   renderer.render( scene, camera );
      // };
      //  animate();


      ////////////////////////  RESIZE  //////////////////////////////

      function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerWidth;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth / 2, window.innerWidth / 2);
      }
      window.addEventListener("resize", onWindowResize);


      console.log(scene);
    }
  }

  ////////////////////////  Return  //////////////////////////////













  
  render() {
    return (

      <div className="AppD">
        <ambientLight intensity={0.4} />
        <div ref={ref => (this.mount = ref)} />

      </div>



    )
  }
}

export default ThreeDMouse;
