import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";


export const InfoContainer = styled.div`
color: #fff;
background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};

@media screen and (max-width: 768px) {
    padding: 100px 0;

}`;

export const InfoWrappper = styled.div`
display: grid;
z-index: 1;
height: 1000px;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
padding: 0 0px;
justify-content: center;
`;

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 768px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`}
}`;

export const Column1 = styled.div`
width: 50vw;
margin-bottom: 15px;
padding: 0 25px;
grid-area: col1;

`;
export const Column2 = styled.div`
width: 50vw;
background-color: white;
margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;
`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0 ;
padding-bottom: 60px;
`;

export const TopLine = styled.p`
color: #01bf71;
font-size: 16px;
line-height: 16px;
font-weight: 700;display: flex;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 16px;

@media screen and (max-width: 480px) {
    font-size: 10px;
}`;

/* export const Heading = styled.h1`
display: flex;
margin-bottom: 24px;
font-size: 48px;
line-height:1.1;
font-weight: 600;
color: white;
`; */

export const Heading = styled.h1`
/* text-shadow: 3px 3px 10px #191919; */
margin-top:35px;
font-size: 8.5rem;
margin-bottom: 24px;
font-size: 38px;
line-height:1.15;
display: flex;
text-shadow:silver;
font-weight: 800;
color:white;
font-style:uppercase;
opacity: 1;margin-bottom:30px;
/* text-shadow: 1.2px 1.2px 1.4px #d1d1d1;
 */
@media screen and (max-width: 680px) {
    font-size: 22px;
margin-left:20px;
}`;

export const Subtitle = styled.p`
max-width: 440px;
display: flex;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export const BtnWrap = styled.div`
display: flex;
justify-content: flex-start;
`;

export const ImgWrap = styled.div`
max-width: 1055px;
height: 100%;
`;

export const Img = styled.img`
width: 100%;
margin: 0 0 10px 0;
padding-right: 0;
`;
/* ////////////////////////////DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD/////////////////////////////////////////////
FFFFFFFFFFFFFFFOOOOOOOOOOOOOORRRRRRRRRRRMMMM */



export const HeadingF = styled.h1`
display: flex;
margin-bottom: 24px;
font-size: 48px;
line-height:1.1;
font-weight: 600;
color:white;
`;


export const TopLineF = styled.p`
font-size: 25.5px;
line-height: 30px;
font-weight: 700;
letter-spacing: 1.4px;
/* text-transform: uppercase; */
margin-bottom: 35px;
/* color:#FF3B26; */
/* color:#3a54ff; */
/* color:#7FF551; */
color:#26de81;
/* color: #eb3b5a; */
text-decoration-color: transparent whitesmoke; 
opacity: 1;

/* text-shadow: 2px 2px 10px white;
 */@media screen and (max-width: 680px) {
 margin-top: 30px;
 margin-left:20px;
font-size:22px;
}`;







/* ////////////////////////////DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD/////////////////////////////////////////////
 */
export const BLink = styled(LinkS)`
`;


export const DInfoContainer = styled.div`
/* max-width: 100vw; */
/* background: ${({ DlightBg }) => (DlightBg ? "#f9f9f9" : "#121212")};
*/
background-color: #121212;
/* -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px); */

/* padding-top: 20px; */
@media screen and (max-width: 768px) {
    width: 100vw;
    background-image:linear-gradient(180deg, #121212 88%, #191919 100%);

}`;


export const DInfoContainerB = styled.div`
/* color: #fff; */
/* background: ${({ DlightBg }) => (DlightBg ? "#f9f9f9" : "#121212")};
 *//* margin-bottom: 2000px; 
 */@media screen and (max-width: 768px) {
/*     padding: 100px 0; */
width: 100vw;
}`;



export const DInfoWrappper = styled.div`
display: grid;

height: 100vh; 

padding-left:15vw;

padding-right:5vw;
@media screen and (max-width: 680px) {
    padding-left:20px;
/*     height: 93vh;  */
}`;

export const DTextWrapper = styled.div`
width: 600px;
max-width: 90vw;
/* padding-top: 0 ;
padding-bottom: 60px; */
`;


export const DInfoWrappperB = styled.div`
display: grid;
z-index: 1;
height: 100vh; 
max-width: 100vw;

/* padding-bottom:500px; */
justify-content: center;
`;


export const p = styled.div`
text-align:left;
background-color: white;
font-size:20px;
`;

export const DInfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
padding-left:0px;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 680px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`};

}`;




export const DInfoRowB = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
padding-left:0px;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2`};
background-color:white;

@media screen and (max-width: 280) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`};

}`;

export const InfoRowB  = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;

grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 780px) {
    grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};
    margin-left:10vw;
}`;


/* export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 768px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`}
}`; */

export const D1Column1 = styled.div`

text-align: center;
/* width:280px;  */
grid-area: col1;
/* margin-left:80px; */
@media screen and (max-width: 680px) {
  justify-content: center;
  width:30vw;
  margin-left:0px;
}`;

export const D1Column2 = styled.div`
text-align: center;
/* width:120px; */
grid-area: col2;

@media screen and (max-width: 680px) {
    justify-content: center;
  width:30vw;
  margin-left:20px;
}`;



export const DInfoRowForm = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr); 
justify-content: center;


grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 680px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`};

}`;

export const DColumn1Form = styled.div`
grid-area: col1;
height:100px;
margin-top:30px;
font-size:20px;
`;


export const DColumn2Form = styled.div`
height:100px;
margin-top:30px;
text-align: left;
grid-area: col2;
font-size:20px;
`;



export const DColumn1 = styled.div`
margin-bottom: 0px;
grid-area: col1;


`;

export const DColumn2 = styled.div`
margin-bottom: 15px;

padding-left: 60px;
padding-right: 15px;
grid-area: col2;
@media screen and (max-width: 1300px) {

    display: none
}`;


export const DColumn3 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col3;
`;


export const DTopLine = styled.p`
font-size: 25.5px;
line-height: 30px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 35px;
/* color:#FF3B26; */
/* color:#3a54ff; */
/* color:#7FF551; */
color:#FF3B26;
/* color: #eb3b5a; */
text-decoration-color: transparent whitesmoke; 
opacity: 1;

/* text-shadow: 2px 2px 10px white;
 */@media screen and (max-width: 680px) {
 margin-top: 30px;
 margin-left:20px;
font-size:22px;
}`;






export const DHeading = styled.h1`
/* text-shadow: 3px 3px 10px #191919; */
margin-top:90px;
font-size: 8.5rem;
margin-bottom: 24px;
font-size: 38px;
line-height:1.15;
display: flex;
text-shadow:silver;
font-weight: 800;
color:grey;
opacity: 1;
/* text-shadow: 1.2px 1.2px 1.4px #d1d1d1;
 */
@media screen and (max-width: 680px) {
    font-size: 22px;
margin-left:20px;
}`;








export const DSubtitle = styled.p`
/* text-shadow: 2px 2px 3px #191919;
 *//* text-shadow: 0.1px 0.1px 0.2px #d1d1d1;
 */
 display: flex;
margin-bottom: 55px;
font-size: 24px;
padding-top:10px;
line-height: 24px;
align-items:center;
color: ${({ DdarkText }) => (DdarkText ? "#010606" : "#999999")};
opacity: 1;
@media screen and (max-width: 680px) {
 align-items:center;

 font-size: 19px;
 width:80vw;margin-left:20px;
}`;

export const DBtnWrap = styled(LinkS)`
opacity: 1;
display: flex;
justify-content: flex-start;

@media screen and (max-width: 680px) {
    padding: 20px 0;

  justify-content: center;
 



}`;


export const DImgWrap = styled.div`
width: 800px;
max-width: 1100px;
height: 100%;
`;

export const DImgWrapB = styled.div`
 width: 100vw;
/* max-width: 1100px; */
/* height: 100%;
background-color: #f15946;
height:2000px; */
`;

export const HTMLContent = styled.div`
max-width: 1000px;
height: 100%;
`;


export const DImg = styled.img`
width: 100%;
margin: 0 0 10px 0;
padding-right: 0;
`;


///////////////////DDDDDDDDDDDDDDDDDDDDDDDDDDDDD              22222222222222222222222222/////////////////////////////////////////////////////////////////////////////////



export const D2InfoContainer = styled.div`
/* color: #fff;
 */background: ${({ DlightBg }) => (DlightBg ? "#f9f9f9" : "#121212")};
padding-top: 10px;
@media screen and (max-width: 768px) {
    padding: 100px 0;

}`;

export const D2InfoWrappper = styled.div`
display: grid;
z-index: 1;
height: 1000px;
width: 100vw;
/* max-width: 1220px; */
margin-right: auto;
margin-left: auto;
padding: 0 0px;
justify-content: center;

`;

export const D2InfoRow = styled.div`
/* display: grid;
grid-auto-columns: minmax(auto, 1fr); */
margin-top:18px;
align-items: center;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 768px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`}
}`;

export const D2InfoRow1 = styled.div`
 display: grid; 
 grid-auto-columns: minmax(autoForward, 1fr); 
margin-bottom:0px;
/* background-color:white;
 */align-content: center;
align-items: center;
text-align: center;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2"`};


@media screen and (max-width: 768px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`}
}`;

export const D4InfoRow2 = styled.div`
 display: grid; 
/*  grid-auto-columns: minmax(autoForward, 1fr); 
margin-bottom:0px; */
/* background-color:white;
 */align-content: center;
align-items: center;
text-align: center;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2 "`};


@media screen and (max-width: 768px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`}
}`;

export const D3ColumnA = styled.div`
margin-bottom: 0px;
padding: 0px 0px;
 grid-area: col1; 
text-align: center;
width:150px;
margin-right:20px;
`;
export const D3Column2A = styled.div`
margin-bottom: 0px;

padding: 0px 0px;
 grid-area: col1; 
text-align: center;
width:50px;
margin-right:20px;
`;

export const DTColumn1 = styled.div`
margin-bottom: 20px;
padding: 0 0px;
grid-area: col1;
`;

export const DTColumn2 = styled.div`
margin-bottom: 20px;
margin-left: 0px;
padding: 0px 0px;
grid-area: col2;
`;

export const D4Column1 = styled.div`
margin-bottom: 0px;
padding: 0px 0px;
text-align: center;
width:0px;
justify-content:center;margin-left:280px;
align-items:center;text-align:center;text-align:center;
grid-area: col1;
`;

export const D4Column2 = styled.div`
margin-bottom: 0px;
padding: 0px 0px;
text-align: center;
justify-content:center;text-align:center;
width:0px;
margin-left:0px;
grid-area: col2;align-items:center;
`;


export const D2Column1 = styled.div`
margin-bottom: 0px;
padding: 0px 0px;
text-align: center;
width:120px;
margin-right:0px;
grid-area: col1;
`;

export const D2Column2 = styled.div`
margin-bottom: 0px;
padding: 0px 0px;
text-align: center;
width:120px;
margin-right:0px;
grid-area: col2;
`;

export const D2Column3 = styled.div`
margin-bottom: 0px;
padding: 0px 0px;
grid-area: col3;
text-align: center;
width:120px;
margin-right:0px;

`;



export const DColumnT1 = styled.div`
margin-bottom: 0px;

grid-area: col1;

`;

export const DColumT2 = styled.div`
margin-bottom: 15px;

grid-area: col2;
`;

export const D5Column1 = styled.div`
margin-bottom: 0px;

grid-area: col1;

`;

export const D5Column2 = styled.div`
margin-bottom: 0px;

grid-area: col2;
`;


export const D2TextWrapper = styled.div`
width: 50vw;
max-width:1000px;
/* background-color: white; */
 padding-top: 0 ;
padding-bottom: 60px;
justify-content: center;
`;

export const D5TextWrapper = styled.div`


/* background-color: white; */
 padding-top: 0 ;
padding-bottom: 0px;
justify-content: center;
text-align: center;
align-items: center;
`;
export const D5InfoWrappper = styled.div`
display: grid;
z-index: 1;
height: 1000px;
width: 100%;

max-width: 1220px;
margin-right: auto;
margin-left: auto;
padding: 0 0px;
justify-content: center;
`;
export const D2TopLine = styled.p`
font-size: 25.5px;
line-height: 18px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 45px;
margin-top: 65px;
color:#FF3B26;
/* color:#FF3B26; */
border-color: #fff;
/*  text-shadow: 1px 1px 3px #FF3B26;
 */text-decoration-color: transparent whitesmoke; 
 text-align: center;
opacity: 1;
/* filter: blur(15px);
 */`;

export const D4TopLine = styled.p`
font-size: 25.5px;
line-height: 18px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 45px;
margin-top: 65px;
text-align: left; 
color:#FF3B26;
/* color:#FF3B26; */
border-color: #fff;
/*  text-shadow: 1px 1px 3px #FF3B26;
 */text-decoration-color: transparent whitesmoke; 
 
opacity: 1;
/* filter: blur(15px);
 */`;


export const D5TopLine = styled.p`

font-size: 25.5px;
line-height: 18px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 10px;
color:#FF3B26;
border-color: #fff;
/*  text-shadow: 1px 1px 3px #FF3B26;
 */text-decoration-color: transparent whitesmoke; 

opacity: 1;
/* filter: blur(15px);
 */`;

export const D2Heading = styled.h1`
text-shadow: 3px 3px 10px #191919

;
margin-top: 24px;
font-size: 8.5rem;
margin-bottom: 64px;
font-size: 40px;
line-height:1.15;
text-shadow:silver;font-weight: 800;
color: ${({ DlightText }) => (DlightText ? "#f7f8fa" : "#010606")};
opacity: 1;
/* text-shadow: 1.2px 1.2px 1.4px #d1d1d1;
 */
@media screen and (max-width: 480px) {
    font-size: 32px;
    text-align:center;
}`;


export const D5Heading = styled.h1`
text-shadow: 3px 3px 10px #191919;
font-size: 8.5rem;
font-size: 40px;
line-height:1.15;
text-shadow:silver;
font-weight: 800;
color: ${({ DlightText }) => (DlightText ? "#f7f8fa" : "#010606")};
opacity: 1;
/* text-shadow: 1.2px 1.2px 1.4px #d1d1d1;
 */
@media screen and (max-width: 480px) {
    font-size: 32px;
    text-align:center;
}`;


export const D4Heading = styled.h1`
text-shadow: 3px 3px 10px #191919;
margin-top: 24px;
font-size: 8.5rem;
margin-bottom: 64px;
font-size: 40px;
line-height:1.15;
text-shadow:silver;font-weight: 800;
color: ${({ DlightText }) => (DlightText ? "#f7f8fa" : "#010606")};
opacity: 1;
text-align:left;
/* text-shadow: 1.2px 1.2px 1.4px #d1d1d1;
 */
@media screen and (max-width: 480px) {
    font-size: 32px;
    text-align:center;
}`;


export const D2Heading1 = styled.h1`
/* text-shadow: 1px 1px 2px #191919;
 */text-align: left;
/* margin-top: 40px; */
text-align:center;
margin-bottom: 80px;
margin-top: 50px;
font-size: 40px;
line-height:1.15;
text-shadow:silver;font-weight: 800;
color: ${({ D2lightText }) => (D2lightText ? "#f7f8fa" : "#010606")};
opacity: 1;

/* text-shadow: 1.2px 1.2px 1.4px #d1d1d1;
 */
@media screen and (max-width: 480px) {
    font-size: 32px;
    text-align:center;
}`;


export const D2Subtitle = styled.p`
/* text-shadow: 2px 2px 3px #191919;
 *//* text-shadow: 0.1px 0.1px 0.2px #d1d1d1;
 */

margin-bottom: 15px;
font-size: 20px;

color: ${({ DdarkText }) => (DdarkText ? "#b5b5b5" : "#c9c9c9")};
opacity: 1;
text-align: left; 
`;

export const D2BtnWrap = styled.div`
opacity: 1;
display: flex;
justify-content: flex-start;
justify-content: center;
text-align:center;
margin-right:183px;
margin-top: 95px;
`;

export const D2ImgWrap = styled.div`
width: 800px;
max-width: 1100px;
height: 100%;
`;

export const D2Img = styled.img`
width: 100%;
margin: 0 0 10px 0;
padding-right: 0;
`;
export const D5ImgWrap = styled.div`
width: 100vw;


`;


/////////////////////////////////////////////////////////////////////////////////////////////////////


export const D3InfoContainer = styled.div`
color: #fff;
background: ${({ DlightBg }) => (DlightBg ? "#f9f9f9" : "#121212")};
padding-top: 10px;
@media screen and (max-width: 768px) {
    padding: 100px 0;

}`;

export const D3InfoWrappper = styled.div`
display: grid;
z-index: 1;
height: 1000px;
width: 100vw;
/* max-width: 1220px; */
margin-right: auto;
margin-left: auto;
padding: 0 0px;
justify-content: center;

`;

export const D3InfoRow = styled.div`
/* display: grid;
grid-auto-columns: minmax(auto, 1fr); */
margin-top:18px;
align-items: center;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2 col3"`};


@media screen and (max-width: 768px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`}
}`;

export const D3InfoRow1 = styled.div`
 display: grid; 
 grid-auto-columns: minmax(autoForward, 1fr); 
margin-bottom:60px;


align-content: center;
align-items: center;
text-align: center;
grid-template-areas: ${({ imgStart }) => imgStart ? `"col2 col1"` : `"col1 col2 col3"`};


@media screen and (max-width: 768px) {
grid-template-areas: ${({ imgStart }) => imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`}
}`;

export const D3Column1 = styled.div`
margin-bottom: 0px;
padding: 0px 0px;
 grid-area: col1; 
text-align: center;
width:150px;
margin-right:70px;
margin-top: 40px;
`;

export const D3Column2 = styled.div`
margin-bottom: 0px;
margin-left: 0px;
padding: 0px 0px;
grid-area: col2;
text-align: center;
width:150px;
margin-right:70px;
margin-top: 40px;
`;

export const D3Column3 = styled.div`
margin-bottom: 0px;
padding: 0px 0px;
grid-area: col3;
text-align: center;
width:150px;
margin-right:70px;
margin-top: 40px;
`;

export const D3TextWrapper = styled.div`
width: 80vw;
max-width:900px;
/* background-color: white; */
 padding-top: 0 ;
padding-bottom: 60px;
justify-content: center;
`;

export const D3TopLine = styled.p`
font-size: 25.5px;
line-height: 18px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 45px;
margin-top: 65px;
color:#FF3B26;
/* color:#FF3B26; */
border-color: #fff;
/*  text-shadow: 1px 1px 3px #FF3B26;
 */text-decoration-color: transparent whitesmoke; 
 text-align: center;
opacity: 1;
/* filter: blur(15px);
 */`;










export const D3Heading = styled.h1`
/* text-shadow: 1px 1px 2px #191919;
 */text-align: left;
/* margin-top: 40px; */
text-align:center;
margin-bottom: 80px;
margin-top: 50px;
font-size: 40px;
line-height:1.15;
text-shadow:silver;font-weight: 800;
color: ${({ D2lightText }) => (D2lightText ? "#f7f8fa" : "#010606")};
opacity: 1;

/* text-shadow: 1.2px 1.2px 1.4px #d1d1d1;
 */
@media screen and (max-width: 480px) {
    font-size: 32px;
    text-align:center;
}`;


export const D3Subtitle = styled.p`
/* text-shadow: 2px 2px 3px #191919;
 *//* text-shadow: 0.1px 0.1px 0.2px #d1d1d1;
 */

margin-bottom: 5px;
font-size: 23px;

color: ${({ DdarkText }) => (DdarkText ? "#b5b5b5" : "#c9c9c9")};
opacity: 1;
text-align: left;  text-align:left;
`;

export const D3BtnWrap = styled.div`
opacity: 1;
display: flex;
justify-content: flex-start;
justify-content: center;
margin-top: 4px;
`;

export const D3ImgWrap = styled.div`
width: 800px;
max-width: 1100px;
height: 100%;
`;

export const D3Img = styled.img`
width: 100%;
margin: 0 0 10px 0;
padding-right: 0;
`;
