import React from 'react';
import { Container, FormContent, FormLabel, FormWrap, Icon, Form, FormH1,FormInput, FormButton, Text,Text1,Text2,Text3  } from './impressumElements';

 const ImpressDaten = () => {
    return (
        <>
            <Container>
                <FormWrap>
                {/*    <Icon to="/">Scivis3D</Icon> */}
                 <FormContent>
                       <Form action="#">
                       <FormH1>Sign in to your account</FormH1> 
                       <Text1>Impressum</Text1>
                       <Text2>Angaben gemäß § 5 TMG</Text2>
                       <Text>Scivis UG (haftungsbeschränkt) </Text>
                       <Text>Breulstraße 19</Text>
                       <Text>48565 Steinfurt</Text>
                       <Text3>Vertreten durch: </Text3>
                       <Text>Philipp Schwarz</Text>
                       <Text3>Kontakt:</Text3>
                       <Text>Telefon: +49 151 10105709 </Text>
                       <Text>E-Mail: info@scivis3d.com </Text>
                       <Text3>Handelsregistereintragung:</Text3>
                       <Text>Registernummer: HRB 13282</Text>
                       <Text>Registergericht: Amtsgericht Steinfurt</Text>
                       <Text3>Umsatzsteuer</Text3>
                       <Text>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE345422496</Text>
                  

            
                        </Form>
                   </FormContent> 
                </FormWrap>
            </Container>
        </>
    );
};

export default ImpressDaten;