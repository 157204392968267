import React, { useState } from "react"
import "./formstyle.css";
import { db } from "./firebase";
import { useForm } from "react-hook-form";
import { DTopLine, DHeading, InfoContainer, Subtitle, TopLine, DInfoRow, DColumn1, DColumn2, DInfoRowForm, DColumn1Form, DColumn2Form, HeadingF, TopLineF } from "../InfoSection/InfoElements";
import { TextWrapper } from "../ThreeDSection/InfoDElements";
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { TextField, Checkbox } from "@material-ui/core";

const Contact = ({ id, DtopLine,
    DlightText, DTopLine,
    DheadLine,
    DdarkText,
    Ddescription, DTopLineF,DTopLineF2
}) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const { register, watch, } = useForm();

    const [loader, setLoader] = useState(false)



    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)

        db.collection("contacts").add({
            name: name,
            email: email,
            massage: message,
        })
            .then(() => {
                alert("Message has been sent 👍");
                setLoader(false);
            })
            .catch((error) => {
                alert(error.message);
                setLoader(false);
            });

        setName("");
        setEmail("");
        setMessage("");

    };




    return (
        <InfoContainer id={id}>
            <form className="formContact" onSubmit={handleSubmit}>
                {/*    <TextWrapper>      </TextWrapper>  */}
                <TopLineF >{DTopLineF}</TopLineF>
                <TopLineF >{DTopLineF2}</TopLineF>
                {/*         <DHeading >{DheadLine}</DHeading> */}
                {/*    <Subtitle >{Ddescription}</Subtitle>
        <HeadingF >{DheadLine}</HeadingF> */}
                <DInfoRowForm >
                    <DColumn1Form>
                        <MailIcon style={{ fontSize: 50, }} />  &nbsp;info@scivis3d.com
                    </DColumn1Form>
                    <DColumn2Form>
                        <PhoneIcon style={{ fontSize: 50, justifyContent: "center", }} /> &nbsp; 0151&nbsp;10105709
                        <br></br>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Tel. oder Whatsapp) </p>
                    </DColumn2Form>

                    {/*     <DColumn2Form>   +49 015110105709</DColumn2Form> */}
                </DInfoRowForm>



       
{/*    
                <label>Name</label>
                <input placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <label htmlFor="email">Email</label>
                <input type="email" placeholder="Email"

onChange={(e) => setEmail(e.target.value)} 
                    {...register("email")} />

                <label>Message</label>
                <textarea placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <Checkbox>
                </Checkbox>  Ich stimme den <a style={{ color: "#3a54ff" }} href="/Datenschutz">Datenschutzvereinbarungen</a> zu .
                <input type="submit" class="neon-button"  /> */}
                


{/*                 style={{background: loader ? "#ccc" : "rgb(2,2, 110)"}} */}

            </form>
            {/*  <form action="#" onsubmit="if(document.getElementById('agree').checked) { return true; } else { alert('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy'); return false; }">

<input type="checkbox" name="checkbox" value="check" id="agree" /> I have read and agree to the Terms and Conditions and Privacy Policy
<input type="submit" name="submit" value="submit" />

</form> */}
        </InfoContainer>

    );
};

export default Contact