import React from 'react'
import JanAufsteller from '../ARSection/ARjanAufsteller';
import {
    InfoContainerN,

     Column2N, 
} from './Hero3DElement';




const AufstellerAR = ({
    lightBg,
    id,
  
    dark2,toggleHome }) => {
    return (
        <>


            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}


                        <Column2N>
        
                        <JanAufsteller/>
                   
                        </Column2N>
                

            </InfoContainerN>
        </>
    );
};

export default AufstellerAR;


















