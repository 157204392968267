import React from 'react'
import {
    InfoContainerK,InfoContainerN,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap, Schrift3,Schrift4,SchriftContainer4,Schrift5,Schrift7,Schrift8,Schrift9,
    Img2, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB,Schrift2, SchriftContainer2, Schrift, SchriftContainer, Column2N, InfoContainerNB, Schrift6, D1ColumnL, D1ColumnLA, InfoContainerNBP
} from './Hero3DElement';
import logo from './galerielaing.png';
import profilkirkeby from './perkirkeby1001.jpg';



import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";

import ARN from '../ARSection/arnuhr';
import { NavLogo } from '../Navbar/NavbarElements';
import HackDame from '../ARSection/ARHackDame';
import HackDameklein from '../ARSection/ARKleineDame';
import ARN2 from '../ARSection/arnuhr2';
import ARK from '../ARSection/kerkeby';
import ARKB from '../ARSection/kerkebybl';

const KerbebyAR = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,toggleHome }) => {
    return (
        <>
{/*         <InfoContainerNB >
        <NavLogo > <Img2 class="flex" src={logo} alt="Logo" /> </NavLogo>
        </InfoContainerNB> */}
        <SchriftContainer4>
                    
                        <Schrift6 >Per Kirkeby</Schrift6>   
    
        <InfoContainerNBP >
        <NavLogo > <Img2 class="flex" src={profilkirkeby} alt="Logo" /> </NavLogo>
        
        </InfoContainerNBP>
        <Schrift8 >Maler, Bildhauer, Architekt und Dichter
</Schrift8>    
{/* <Schrift8 >
Geboren 1. September 1938 in Kopenhagen
</Schrift8> */}
{/* <Schrift8 >
Lebt und arbeitet in Düsseldorf-Ratingen</Schrift8> */} 





{/*         <Schrift9 >Per Kirkeby ist einer der wichtigsten zeitgenössischen europäischen Künstler. Seine Arbeiten befinden sich in den Sammlungen der bedeutendsten Museen weltweit (Auswahl: Centre Georges Pompidou, Paris; The Tate Gallery, London; Stedelijk Museum, Amsterdam, Metropolitan Museum, New York, Louisiana Museum, Dänemark; Städtische Galerie im Lenbachhaus, München; Museum of Modern Art, New York). 1976 und 1980 werden seine Werke auf der Biennale in Venedig gezeigt. 1982 wird er zur documenta 7 eingeladen. 1986 entwirft er zwei Backsteinskulpturen für die Skulptur Projekte 1987 in Münster.</Schrift9>    
 */}
{/*         <D1ColumnLA>
           <a href="https://www.galerie-laing.com/per-kirkeby/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingk">mehr Infos</a> 
</D1ColumnLA> */}

                        <Schrift5 >{/* neue Möglichkeiten der Betrachtung  */}</Schrift5>                          
                        </SchriftContainer4>
         




            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}



                        <Column2N>
                        
                        <SchriftContainer>
                        <Schrift7 >Augmented Reality Anleitung </Schrift7> 
 <Schrift3 >Mit dem Handy je nach Objekt die Wand oder den Boden der Wahl anvisieren, den "View in AR" Button betätigen,
  mit dem Handy die Fläche abscannen. Sobald das Objekt "aufspringt" und ein grauer Rahmen um das Objekt erscheint, 
  kann man es an die gewünschte Stelle positionieren.</Schrift3>                       
 </SchriftContainer>

                        <SchriftContainer2>
                       
                            <Schrift2>Titel: Ohne Titel, 1993 </Schrift2>                          
                        <Schrift2 >Technik: Gouache, Pastell, Kohle, Tusche auf Bütten</Schrift2>  
                        <Schrift2>Maße: 78.5cm x 105.3cm</Schrift2>                      
                        </SchriftContainer2>
                        <ARKB/>  
                        

                            <SchriftContainer2>
                       
                       <Schrift2>Titel: Ohne Titel, 2009 </Schrift2>                          
                   <Schrift2 >Technik: Gouache, Pastell, Kohle, Bleistift auf Bütten</Schrift2>  
                   <Schrift2>Maße: 43 cm x 61 cm</Schrift2>                      
                   </SchriftContainer2>

                   <ARK/>


                            <D1ColumnLA>
           <a href="https://www.galerie-laing.com/kontakt/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingko">Kontakt</a> 
</D1ColumnLA>


                        </Column2N>
                

            </InfoContainerN>
        </>
    );
};

export default KerbebyAR;


















