import React, { useState } from "react";
import "@google/model-viewer/dist/model-viewer";
import "./styles.css";
import { element } from "prop-types";



function Prozession() {




    return (

        <>

            <model-viewer id="3d"
                src="prozession.glb"  ios-src="prozession.usdz"
                alt="Prozession"
                exposure="0.52"
           
                shadow-intensity="4"
                shadow-softness="1"
                auto-rotate
                ar camera-controls
                max-camera-orbit="auto 91deg auto"
                min-camera-orbit="auto 50deg auto"
                camera-orbit="45deg 75deg 2.5m ">
 <button slot="ar-button" id="ar-button">  View in AR</button>

            </model-viewer>



        </>




    );
}


export default Prozession;


