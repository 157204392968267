import firebase from "firebase";

var firebaseApp = firebase.initializeApp({

        apiKey: "AIzaSyC628qOqwyowkYeY0_J6LBs1rcXTOeaU9w",
        authDomain: "scivis3d.firebaseapp.com",
        projectId: "scivis3d",
        storageBucket: "scivis3d.appspot.com",
        messagingSenderId: "722368458571",
        appId: "1:722368458571:web:949eb0fc8f96d2091b82ce",
        measurementId: "G-7Z03CQY1S4"
      });
    



var db = firebaseApp.firestore();

export {db};



