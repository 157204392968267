import React from "react";
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "./pages";
import SigninPage from "./pages/signin";
import ImpressumPage from "./pages/impressum";
import DatenPage from "./pages/datenschutz";
import JanPage from "./pages/janhagmann";
import DanielPage from "./pages/DanielJakobian";
import GallerieOstendorffPage from "./pages/GallerieOstendorff";
import PellePage from "./pages/pelle";
import LaingPage from "./pages/GallerieLiang";
import Seinroetterpage from "./pages/GalerieSteinroetter";
import HackPage from "./pages/klaushack";
import NuhrPage from "./pages/dieternur";
import KerkebyPage from "./pages/Kerkeby";
import LuepertzPage from "./pages/luepertspage";
import testpage from "./pages/testpage";
import Nuhrmuenster from "./components/Hero3DSection/nuhrmuenster";
import Nuhressen from "./components/Hero3DSection/nuhressen";
import a3c5d1Page from "./pages/a3c5d";
import JinyPage from "./pages/jinypage";
import webgPage from "./pages/webg";
import GuPage from "./pages/gu";
import DatenAppKunstImParkPage from "./pages/DatenschutzAppKunstimPark";
import DownloadApp from "./pages/DownloadApp";
import ARHackPage from "./pages/arHackPage";
import ARKerkebyPage from "./pages/ARKirkBlauPage";
import ARNuhrPage from "./pages/ARDieterKhPage";
import ARGuPage from "./pages/ARGuXiaPage";
import ARKirkebySkulpturPage from "./pages/ARKirkebySkulpturPage";
import ARLuepertzSkulpturPage from "./pages/ARLuepertzSkulpturPage";




function App() {
  return (
    <Router>
        <Switch>
        <Route path="/" component={Home} exact/>
        <Route path="/signin" component={SigninPage} exact/>
        <Route path="/jan-hagmann" component={JanPage} exact/>
       <Route path="/ar123" component={PellePage} exact/>
       <Route path="/Impressum" component={ImpressumPage} exact/>
        <Route path="/Datenschutz" component={DatenPage} exact/>
        <Route path="/Datenschutz-App-Kunst-im-Schlosspark" component={DatenAppKunstImParkPage} exact/>
        <Route path="/App-Kunst-im-Schlosspark" component={DownloadApp} exact/>

        <Route path="/galerie-laing" component={LaingPage} exact/> 
        <Route path="/galerie-laing-klaus-hack" component={HackPage} exact/> 
        <Route path="/galerie-laing-dieter-nuhr" component={NuhrPage} exact/> 
       {/*  <Route path="/galerie-steinroetter" component={Seinroetterpage} exact/>     */}
        <Route path="/galerie-laing-per-kirkeby" component={KerkebyPage} exact/> 
        <Route path="/galerie-laing-markus-luepertz" component={LuepertzPage} exact/> 
        <Route path="/testpage3315n6" component={testpage} exact/> 
        <Route path="/galerie-laing-dieter-nuhr-muenster" component={Nuhrmuenster} exact/> 
        <Route path="/galerie-laing-dieter-nuhr-essen" component={Nuhressen} exact/> 
        <Route path="/a3c5d1" component={a3c5d1Page} exact/> 
        <Route path="/galerie-laing-jiny-lang" component={JinyPage} exact/> 
        <Route path="/galerie-laing-gu-xiaoping" component={GuPage} exact/> 

        <Route path="/galerie-laing-7dg2789frtb369ws" component={ARHackPage} exact/>   {/*  kleine Dame Hack */}
        <Route path="/galerie-laing-830djwonsocndpsx" component={ARKerkebyPage} exact/> {/*  Kirkeby blau */}
        <Route path="/galerie-laing-vbsosw9dwsinddis" component={ARGuPage} exact/> {/*  gu xiaoping blau */}
        <Route path="/galerie-laing-s0ssndiss0dsnaxs" component={ARNuhrPage} exact/> {/*  Dieter Nuhr Kasastan */}

        <Route path="/galerie-laing-xmfkidkmfodpdemd" component={ARLuepertzSkulpturPage} exact/> {/*  Lüpertz */}
        <Route path="/galerie-laing-xmjfepijdoijdidk" component={ARKirkebySkulpturPage} exact/> {/*  Kirkeby  */}




{/*                <Route path="/webgPage" component={webgPage} exact/> 
 */} 
      </Switch>
    </Router>
  );
}

export default App;
