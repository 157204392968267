import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";



export const Nav = styled.nav`
background: ${({ scrollNav }) => (scrollNav ? "#262626" : "transparent")};
//background-color:  "transparent";

height: 80px;
margin-top:-80px; 
display: flex;
justify-content: center;
align-items: center;
font-size: 1rem;
position: sticky;
top: 0;
z-index: 100;

@media screen and (max-width: 1368px){
    transition: 0.8s all ease;
    margin-top:0px; 
    height: 60px;
}`;

export const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: 80px;
z-index: 900;
width: 100vw;
padding: 0 24px;
align-items: left;
max-width: 2100px;
/* background-color: #121212; */
-webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  @media screen and (max-width:1368px)
{
    height: 60px;

}`;

  




export const NavLogo = styled(LinkR)`
color: #fff;
z-index: 900;
justify-self: flex-start;
cursor: pointer;
font-size: 1.5rem;
display: flex;
align-items: left;
margin-left: 42px;
font-weight: bold;
text-decoration: none;
padding-top:  24px;
&.active {
 border-bottom: 5px inset #1726ff ;
}`;

export const MobileIcon = styled.div`
display: none;

@media screen and (max-width: 1368px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;    
    color: #fff;
}`;

export const NavMenu = styled.ul`
display: flex;
justify-content: center;
align-items: center;
list-style: none;
text-align: center;
margin-right: -22px;
/* padding-top:  24px;
 */@media screen and (max-width: 1368px){
    display: none;
}`;

export const NavItem = styled.li`
height: 54px;
`;

export const NavLinks = styled(LinkS)`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;

&.active {
 border-bottom: 5px inset #1726ff ;
}`;

export const NavBtn = styled (LinkR)`
display: flex;
align-items: center;
padding-right:  64px;

@media screen and (max-width:1368px)
{
   display: none; 
}`;

export const NavBtnLink = styled(LinkR)`
border-radius: 50px;
background: #1726ff;
white-space: nowrap;
padding: 10px 22px;
color:  white;
font-size: 16px;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover {
    transition: all 0.2s ease-in-out;
    background: white ;
    color: black ;
}`;

export const Img = styled.img`
width: 40px;
height: 40px;
padding: 0px 00px;
margin-top:-4px;
margin-right:8px;
margin-left:-24px;

`;

export const SidebarRoute= styled (LinkR)`
/* border-radius: 50px;
background: #3a54ff;
white-space: nowrap;
padding: 16px 64px;
color: #010606;
font-size: 16px;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
color: white;

&:hover {
    transition: all 0.2s ease-in-out;
    background: #fff; 
    color: #3a54ff; */
}`;