import React from "react";
import "@google/model-viewer/dist/model-viewer";
import "./styles.css";


function Test2() {
    return (


<model-viewer  src="1Neuseeland06.glb" ar ios-src="t1bw.usdz"
 ar ar-placement="wall"
 exposure="0.39" 
 ar-scale="fixed"

 ar-modes="webxr scene-viewer quick-look"
  camera-controls alt="Dieter Nuhr"
  max-camera-orbit="auto 98deg auto"
  min-camera-orbit="auto 50deg auto"
  camera-orbit="0deg 75deg 3.2m ">
 <button slot="ar-button" id="ar-button">View in AR</button>
 </model-viewer>
);
}

export default Test2;
