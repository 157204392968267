import React, { useState } from "react";
import "@google/model-viewer/dist/model-viewer";
import "./styles.css";
import { element } from "prop-types";
import Kopf1000 from "../Hero3DSection/HorstAntes";



function ARArtDeco() {




    return (

        <>

            <model-viewer id="3d"
                src="ArtDecoVogel.glb"
                alt="ArtDecoVogel"
                exposure="0.42"
                shadow-intensity="4"
                shadow-softness="1"
                auto-rotate
                 camera-controls disable-zoom
                max-camera-orbit="auto 91deg auto"
                min-camera-orbit="auto 50deg auto"
                camera-orbit="45deg 75deg 1.0m ">



            </model-viewer>



        </>




    );
}


export default ARArtDeco;


