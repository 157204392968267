import React from 'react'
import {
    InfoContainerK,InfoContainerN,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap, Schrift3,Schrift4,SchriftContainer4,Schrift5,
    Img2, InfoRowB, D1ColumnL, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB,Schrift2, SchriftContainer2, Schrift, SchriftContainer, Column2N, InfoContainerNB, BLinkLB
} from './Hero3DElement';
import logo from './galerielaing.png';

import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";

import ARN from '../ARSection/arnuhr';
import { NavLogo } from '../Navbar/NavbarElements';
import HackDame from '../ARSection/ARHackDame';
import HackDameklein from '../ARSection/ARKleineDame';

const NuhrARB = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,toggleHome }) => {
    return (
        <>
        <InfoContainerNB >
        <NavLogo /* to="/" onClick={toggleHome} */> <Img2 class="flex" src={logo} alt="Logo" /> </NavLogo>
        </InfoContainerNB>
        <SchriftContainer4>
                        <Schrift4 >Kunstbetrachtung</Schrift4>   
                        <Schrift4 >in</Schrift4>   
                        <Schrift4 >Augmented Reality</Schrift4>   
                        <Schrift5 >Künstler </Schrift5>                          
                        </SchriftContainer4>
                     
                        <D1ColumnL>
     <a href="https://scivis3d.web.app/galerie-laing-dieter-nuhr"
   smooth={true} 
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaing">Dieter Nuhr</a> 
</D1ColumnL>

<D1ColumnL>

  {/* <BLinkLB href="margin-bottom: 0.5rem;" smooth={true} duration={500} spy={true} exact="true" offset={0}  >   */}       <a href="https://scivis3d.web.app/galerie-laing-klaus-hack"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaing">Klaus Hack</a> {/* </BLinkLB > */}
                               
</D1ColumnL>


<D1ColumnL>
        <a href="https://scivis3d.web.app/galerie-laing-markus-luepertz"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaing">Markus Lüpertz</a>
</D1ColumnL>


<D1ColumnL>
      <a href="https://scivis3d.web.app/galerie-laing-per-kirkeby"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaing">Per Kirkeby</a> 
</D1ColumnL>



<Schrift5 > </Schrift5>                          


            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}



                  {/*       <Column2N>
                        


                        <SchriftContainer2>
                            <Schrift2>Künstler: Dieter Nuhr</Schrift2>
                            <Schrift2>Titel: Schweiz Berninagruppe 03</Schrift2>                          
                        <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                        <Schrift2>Maße: 243cm x 150cm</Schrift2>                      
                        </SchriftContainer2>
                        
                            <ARN/>


                            <SchriftContainer2>
                            <Schrift2>Künstler: Klaus Hack</Schrift2>
                            <Schrift2>Titel: Frauen-Figur</Schrift2>                          
                        <Schrift2 >Material: Kirsche, schwarz-weiß</Schrift2>  
                        <Schrift2>Maße: 202cm x 46cm x 47cm</Schrift2>                      
                        </SchriftContainer2>
                        <SchriftContainer2>
                                            
                        </SchriftContainer2>
                        <HackDame/>
                   


                        <SchriftContainer2>
                            <Schrift2>Künstler: Klaus Hack</Schrift2>
                            <Schrift2>Titel: 2009</Schrift2>                          
                        <Schrift2 >Material: Eiche, weiß gefasst</Schrift2>  
                        <Schrift2>Maße: 69cm x 28cm x 23,5cm</Schrift2>                      
                        </SchriftContainer2>
                        <SchriftContainer2>
                                            
                        </SchriftContainer2>
                        <HackDameklein/>

                        




                        <SchriftContainer>
 
                        <Schrift3 >Anleitung: Mit dem Handy je nach Objekt die Wand oder den Boden der Wahl anvisieren, den "View in AR" Button betätigen,
                         mit dem Handy die Fläche abscannen. Sobald das Objekt "aufspringt" und ein grauer Rahmen um das Objekt erscheint, 
                         kann man es an die gewünschte Stelle positionieren und je nach Wunsch mit zwei Fingern vergrößern oder verkleinern.</Schrift3>                       
                        </SchriftContainer>


                        </Column2N> */}


                

                </InfoContainerN>
            </>
               );
            };

export default NuhrARB;


















