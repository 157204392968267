import React from 'react'
import { Button } from '../ButtonElement'
import {
    InfoContainer,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap,ChangeModelFalse,ChangeModelTrue,HeadingA,
} from './Hero3DElement';

import Img1 from "../../images/vrchat.svg"
import AR1 from '../ARSection/ARSection';
import AR3 from '../ARSection/ARSection3';
import ThreeD1 from '../ThreeDLoader/ThreeDLoader1';
import ThreeSection from '../ThreeDSection/ThreeDSection';
import styled from "styled-components";
import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";
import ARKopf1000 from '../ARSection/ARKopf1000';
import ARArtDeco from '../ARSection/ARArtDeco';




const ArtDeco = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine,
    darkText,
    Ddescription,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2 }) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrappper>
                    <SubWrap>
                        <TopLineT >{DtopLine}</TopLineT>
                        <  BorderWrap />
                    </SubWrap>
                    <InfoRow imgStart={imgStart}>
                        <Column1>

                       <ARArtDeco />  
                     

 


                        </Column1>
                        <Column2>

                            <TextWrapper  >

                                <TopLine >{DtopLine}</TopLine>
                                <HeadingA  >{DheadLine}</HeadingA>
                                <Subtitle DdarkText={darkText}>{Ddescription}</Subtitle>

                                <InfoRowB>

                                    <D1Column1>
                                                             <DBtnWrap to="Kontakt"
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={0} >
                                            <a to="Kontakt"
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                exact="true"
                                                offset={120} class="neon-button">Kontakt</a>

                                        </DBtnWrap>
                                    </D1Column1>

                                    <D1Column2>



                                    </D1Column2>
                                </InfoRowB>

                            </TextWrapper>


                        </Column2>
                    </InfoRow>
                </InfoWrappper>
            </InfoContainer>
        </>
    );
};

export default ArtDeco
