import React from 'react'
import CookieConsent from 'react-cookie-consent';
import Footer from '../components/Footer';
import WallBP1 from '../components/Hero3DSection/ARBP1';
import WallBP2 from '../components/Hero3DSection/ARBP2';
import ARDavid from '../components/Hero3DSection/ARDavid';
import ARJeff from '../components/Hero3DSection/ARJeff';
import ScrollToTop from '../components/ScrollToTop';
import { Icon2 } from '../components/Sidebar/SidebarElements';
import SignIn from '../components/Signin'

const PellePage = () => {
    return (
        <>
        <ScrollToTop/>
        <Icon2>Black Suns & Silver Clouds</Icon2>
         <ARJeff/>
         <ARDavid/>  
         {/*<WallJan3/> */}            
            
         {/*<Icon2>Jan Hagmann 2020</Icon2> */}
{/* 
         <WallBP1/> */}
       {/*   <WallBP2/> */}
        {/*<WallJan7/> */}
            <CookieConsent debug={true}
        buttonText="OK"
        buttonStyle={{ background: "#3a54ff", fontSize: "20px", borderRadius: "15px", color: "white" }}>

        Diese Seite benutzt Cookies um die Funktion der Website zu gewährleisten. Durch die Nutzung der Website stimmen Sie der Verwendung von Cookies zu. Hier erfahren sie mehr über unseren <a style={{ color: "#3a54ff" }} href="/Datenschutz">Datenschutz</a>.
      </CookieConsent>
            <Footer/>
        </>
    )
}

export default PellePage;