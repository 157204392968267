import React from 'react'
import {
    InfoContainerK,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB
} from './Hero3DElement';


import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";
import ARKopf1000 from '../ARSection/ARKopf1000';
import ARWall1 from '../ARSection/ARWall';

const WallSchwan = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2 }) => {
    return (
        <>
            <InfoContainerK lightBg={lightBg} id={id}>
                <InfoWrappper>
                    {/*                     <SubWrap>
                        <TopLineT >{DtopLine}</TopLineT>
                        <  BorderWrap />
                    </SubWrap> */}
                    <InfoRow imgStart={imgStart}>

                        <Column1>

                            <TextWrapper  >

                                <TopLine >{DtopLine}</TopLine>
                                <Heading  >{DheadLine}</Heading>
                                <Subtitle DdarkText={darkText}>{Ddescription}</Subtitle>
                                <Heading  >{DheadLine2}</Heading>
                                <Subtitle DdarkText={darkText}>{Ddescription2}</Subtitle>
                                <Subtitle DdarkText={darkText}>{Ddescription3}</Subtitle>
                                <Subtitle DdarkText={darkText}>{Ddescription4}</Subtitle>
                                <Subtitle DdarkText={darkText}>{Ddescription5}</Subtitle>

                                <InfoRowA>
                                    <D1Column1>
                                        <DBtnWrap >
                                            <BLink to="Service" smooth={true} duration={500} spy={true} exact="true" offset={0}  >         <a to="Service"
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                exact="true"
                                                offset={120} class="neon-button">Next</a> </BLink >
                                        </DBtnWrap>
                                    </D1Column1>
                                    <D1Column2>
                                        <DBtnWrap to="Kontakt"
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={0} >
                                            <a to="Kontakt"
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                exact="true"
                                                offset={120} class="neon-button">Kontakt</a>
                                        </DBtnWrap>
                                    </D1Column2>
                                </InfoRowA>
                            </TextWrapper>

                        </Column1>
                        <Column2K>

                            <ARWall1 />
                        
                        </Column2K>
                    </InfoRow>
                </InfoWrappper>
            </InfoContainerK>
        </>
    );
};

export default WallSchwan;
