import React from 'react'
import {
    InfoContainerK,InfoContainerN,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap, Schrift3,Schrift4,SchriftContainer4,Schrift5,Schrift7,Schrift8,Schrift9,
    Img2, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB,Schrift2, SchriftContainer2, Schrift, SchriftContainer, Column2N, InfoContainerNB, Schrift6, D1ColumnL, D1ColumnLA, InfoContainerNBP
} from './Hero3DElement';
import logo from './galerielaing.png';
import profilnuhr from './dieternuhr1001.jpg';



import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";

import ARN from '../ARSection/arnuhr';
import { NavLogo } from '../Navbar/NavbarElements';
import HackDame from '../ARSection/ARHackDame';
import HackDameklein from '../ARSection/ARKleineDame';
import ARN2 from '../ARSection/arnuhr2';
import ARN3 from '../ARSection/arnuhr3';
import ARN4 from '../ARSection/arnuhr4';
import ARN5 from '../ARSection/arnuhr5';
import ARN6 from '../ARSection/arnuhr6';
import ARN7 from '../ARSection/arnuhr7';
import ARN8 from '../ARSection/arnuhr8';
import ARN9 from '../ARSection/arnuhr9';
import ARN10 from '../ARSection/arnuhr10';
import ARN11 from '../ARSection/arnuhr11';
import ARN13 from '../ARSection/arnuhr12';
import ARN14 from '../ARSection/arnuhr6';
import ARN15 from '../ARSection/arnuhr13';
import ARN16 from '../ARSection/arnuhr6';
import ARE8 from '../ARSection/arnuhressen8';
import ARE4 from '../ARSection/arnuhressen4';
import ARE2 from '../ARSection/arnuhressen2';
import ARE1 from '../ARSection/arnuhressen1';

const NuhrARB = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,toggleHome }) => {
    return (
        <>
 {/*        <InfoContainerNB >
        <NavLogo > <Img2 class="flex" src={logo} alt="Logo" /> </NavLogo>
        </InfoContainerNB> */}
        <SchriftContainer4>
                    
                        <Schrift6 >Dieter Nuhr</Schrift6>   
    
        <InfoContainerNBP >
        <NavLogo > <Img2 class="flex" src={profilnuhr} alt="Logo" /> </NavLogo>
        
        </InfoContainerNBP>
       <Schrift8 >Kabarettist, Autor und Fotograf
</Schrift8>  {/*    
<Schrift8 >
Geboren 29. Oktober 1960 in Wesel
</Schrift8>
<Schrift8 >
Lebt und arbeitet in Düsseldorf-Ratingen</Schrift8>  */}





{/*         <Schrift9 >Dieter Nuhrs Arbeiten werden in zahlreichen Museen und Galerien gezeigt, zuletzt im Luxehills Art Museum Chengdu/China, demnächst im Puschkin Museum St. Petersburg.</Schrift9>    
 */}
{/*         <D1ColumnLA>
           <a href="https://www.galerie-laing.com/dieter-nuhr/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingk">mehr Infos</a> 
</D1ColumnLA> */}

<SchriftContainer>
{/*                         <Schrift7 >Auswahl der Werke der Ausstellungen  </Schrift7> 
 */}{/*  <Schrift3 >Mit dem Handy je nach Objekt die Wand oder den Boden der Wahl anvisieren, den "View in AR" Button betätigen,
  mit dem Handy die Fläche abscannen. Sobald das Objekt "aufspringt" und ein grauer Rahmen um das Objekt erscheint, 
  kann man es an die gewünschte Stelle positionieren.</Schrift3> */}                       
 </SchriftContainer>


{/*  <D1ColumnLA>
           <a href="https://scivis3d.web.app/galerie-laing-dieter-nuhr-muenster"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingaus">Münster  14.11</a> 
</D1ColumnLA>



                            <D1ColumnLA>
           <a href="https://scivis3d.web.app/galerie-laing-dieter-nuhr-essen"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingaus">Essen  21.11</a> 
</D1ColumnLA> */}




                        <Schrift5 >{/* neue Möglichkeiten der Betrachtung  */}</Schrift5>                          
                        </SchriftContainer4>
         




            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}



                        <Column2N>
                        

                        <SchriftContainer2>
                       <Schrift2>Titel: Neuseeland South Island West Coast 01</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 243cm x 150cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN4/> 


                           <SchriftContainer2>                      
                       <Schrift2>Titel: Schweiz Berninagruppe 03</Schrift2>                          
                   <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                   <Schrift2>Maße: 243cm x 150cm</Schrift2>                      
                   </SchriftContainer2>
                   
                       <ARN/>

                       <SchriftContainer2>
                       <Schrift2>Titel: Äthiopien Abijatta 04</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 240cm x 240cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARE1/>  

                           <SchriftContainer2>
                       <Schrift2>Titel: Jemen Marib 01</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 300cm x 200cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARE2/> 

                           <SchriftContainer2>
                       <Schrift2>Titel:Italien Costiera Amalfitana 07</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 200cm x 200cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN5/> 



                           <SchriftContainer2>
                       <Schrift2>Titel: Bhutan Paro 08</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 300cm x 200cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARE4/> 


                           <SchriftContainer2>
                       <Schrift2>Titel: Sudan Khartoum 09</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 100cm x 100cm</Schrift2>                      
                   </SchriftContainer2>


                   <ARE8/> 


                        </Column2N>
                

            </InfoContainerN>
        </>
    );
};

export default NuhrARB;


















