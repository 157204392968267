import React from 'react'
import CookieConsent from 'react-cookie-consent';
import Footer from '../components/Footer';

import WallBP2 from '../components/Hero3DSection/ARBP2';
import ARDavid from '../components/Hero3DSection/ARDavid';
import ARJeff from '../components/Hero3DSection/ARJeff';
import KerbebyAR from '../components/Hero3DSection/ARKerbeby';
import HackAR from '../components/Hero3DSection/hack';
import NuhrARB from '../components/Hero3DSection/Nuhr';

import ScrollToTop from '../components/ScrollToTop';
import { Icon2 } from '../components/Sidebar/SidebarElements';
import SignIn from '../components/Signin'
import KerbebyARAusstellung from '../components/Hero3DSection/ARAusstellungKirkeby';
import KerbebySkulpturARAusstellung from '../components/Hero3DSection/ARAusstellungKirkebySkulptur';

const ARKirkebySkulpturPage = () => {
    return (
        <>
        <ScrollToTop/>
{/*         <Icon2>Gallerie Liang</Icon2> */}
            
        {/*<Icon2>Jan Hagmann 2020</Icon2> */}

         <KerbebySkulpturARAusstellung/>
        

         <CookieConsent debug={true}
        buttonText="OK"
        buttonStyle={{ background: "#3a54ff", fontSize: "20px", borderRadius: "15px", color: "white" }}

      >

        Diese Seite benutzt Cookies um die Funktion der Website zu gewährleisten. Durch die Nutzung der Website stimmen Sie der Verwendung von Cookies zu. Hier erfahren sie mehr über unseren <a style={{ color: "#3a54ff" }} href="/Datenschutz">Datenschutz</a>.
      </CookieConsent>
            <Footer/>
        </>
    )
}

export default ARKirkebySkulpturPage;