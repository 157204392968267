import React from "react";
import "@google/model-viewer/dist/model-viewer";
import "./styles.css";

function AR1() {
    return (
        <div className="AR1">

                <model-viewer
                    src="VaseGeometr.glb"
                    alt="A 3D model of an astronaut"
                    shadow-intensity="5"
                    camera-controls
                    auto-rotate
                    ar   >
                </model-viewer>
          
        </div>
    );
}

export default AR1;