import React, { useRef, useEffect, useState, Suspense, useMemo } from "react";
import { Canvas, useFrame, extend, useThree } from "react-three-fiber";
import ReactDOM from "react-dom";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
/* import { FlyControls } from "three/examples/jsm/controls/FlyControls"; */
/* import { FlyControls } from "@react-three/drei"; */
import { useIntersection } from "react-use";
import gsap from "gsap";
import { useSpring, animated } from 'react-spring'
import { Box, Plane } from "drei";
import ParticleField from "react-particles-webgl";
import { withKnobs, number, boolean } from '@storybook/addon-knobs'
import { Html, useProgress, useGLTF, FlyControls, } from "drei";
import { a, useTransition } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import "./App.scss";
/* import { Column1, Column2, ImgWrap, InfoContainer, InfoRow, InfoWrappper } from "../Hero3DSection/Hero3DElement"; */
import AR1 from "../ARSection/ARSection";
import { Button, DButton } from '../ButtonElement'
import {
    InfoContainer,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img,
} from './InfoDElements';
//--------------LOADER------------------
import Img1 from "../../images/vrchat.svg"
import {
    DInfoContainer, DInfoRow, DInfoWrappper, DColumn1,
    DColumn2,
    DTextWrapper,
    DTopLine,
    DHeading,/* Heading */
    DSubtitle,
    DBtnWrap,
    DImgWrap,
    DImg, D2InfoRow1, D4Column1, D4Column2, D2BtnWrap, D1Column1, D1Column2, BLink, DInfoRowB,InfoRowB
} from "../InfoSection/InfoElements";
import { Vector3 } from "three";
import ThreeDMouse from "./TreeMouse";
import AppM from "./TMouse";
import ThreeD1 from "../ThreeDLoader/ThreeDLoader1";
import TD1 from "../ThreeDSection/TD";
import Fader from "../Fader/Fader";
import { Spring } from "react-spring";
import diamondUrl from "./assets/t.glb"




/* import Three2 from "./Three2"
 */import * as THREE from "three"

import { useFullscreen, useWindowSize } from "react-use"
import { useLoader, render } from "react-three-fiber"



/* import "./neonButton.css" */
import "./nbb1.css"
import { Heading } from "../Hero3DSection/Hero3DElement";













extend({ FlyControls });

extend({ OrbitControls });
/*     extend({ FlyControls });
 */

function Loader() {
    const { active, progress } = useProgress();
    const transition = useTransition(active, {
        from: { opacity: 1, progress: 0 },
        leave: { opacity: 0 },
        update: { progress },
    });
    return transition(
        ({ progress, opacity }, active) =>
            active && (
                <a.div className='loading' style={{ opacity }}>
                    <div className='loading-bar-container'>
                        <a.div className='loading-bar' style={{ width: progress }}></a.div>
                    </div>
                </a.div>
            )
    );
}

//--------------LOAD MODEL------------------


function Model1({ mouse, url, ...props }) {
    const gltf = useGLTF(url, true);





    return <primitive object={gltf.scene} dispose={null} {...props} />;
}

/* 
 /////////////ANIMATE////////////

 document.addEventListener("mousemove", onDocumentMouseMove)

 let mouseX = 0
 let mouseY = 0
 let targetX = 0
 let targetY = 0

const windowX = window.innerWidth/2;
const windowY = window.innerHeight /2;

function onDocumentMouseMove (event) {
    mouseX = (event.clientX - windowX) 
    mouseY = (event.clientY - windowY) 

}

const tick =()=>
{
targetX = mouseX * 0.001
targetY = mouseY * 0.001

Model.rotation.y += 0.5 (targetX -Model.rotation.y)
Model.rotation.x += 0.5 (targetY -Model.rotation.x)
Model.rotation.z += 0.5 (targetY -Model.rotation.x)

} */

//--------------LIGHT------------------


const Lights = () => {
    return (
        <>
            {/* Ambient Light illuminates lights for all objects */}
            <ambientLight intensity={0.2} color="white" />
            {/* Diretion light */}
            <directionalLight position={[10, 10, 5]} intensity={0.4} color="white" />
            <directionalLight
                castShadow
                position={[0, 10, 0]}
                intensity={1.2}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={50}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
                color="white"
            />
            {/* Spotlight Large overhead light */}
            <spotLight intensity={1} position={[1000, 0, 0]} castShadow color="white" shadow-mapSize-width={512}
                shadow-mapSize-height={512}
                castShadow />
        </>
    );
};





//--------------HTMLContent------------------


const HTMLContent = ({
    children,
    modelPath,
    position,
}) => {

    const ref = useRef();
/*     useFrame(() => (ref.current.rotation.y += 0.005));
 */    const [refItem, inView] = useInView({
        threshold: 0,
    });

    return (

        <group position={[0, position, 0]}>
            <mesh ref={ref} position={[10, 8, 0]} scale={[120, 120, 120]} castShadow receiveShadow >
                <Model url={modelPath} />
            </mesh>
            <Html >
                <div ref={refItem} className='container'>
                    <h1 className='title'>{children}</h1>
                </div>
            </Html>
        </group>

    );
};



const CameraControls = () => {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the OrbitControls class.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls

    const {
        camera,
        gl: { domElement }
    } = useThree();

    // Ref to the controls, so that we can update them on every frame using useFrame
    const controls = useRef();
    useFrame(state => controls.current.update());
    return (
        <orbitControls
            ref={controls}
            args={[camera, domElement]}
            enableZoom={true}

            maxAzimuthAngle={Math.PI}
            maxPolarAngle={Math.PI / 2.5}
            minAzimuthAngle={-Math.PI / 4}
            minPolarAngle={0}
        />
    );
};



const CameraFlyControls = () => {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the OrbitControls class.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls

    const {
        camera,
        gl: { domElement }
    } = useThree();

    // Ref to the controls, so that we can update them on every frame using useFrame
    const controls = useRef();
    useFrame(state => controls.current.update());
    return (
        <FlyControls
            autoForward={boolean('AutoForward', false)}
            dragToLook={boolean('DragToLook', false)}
            movementSpeed={number('MovementSpeed', 1.0)}
            rollSpeed={number('RollSpeed', 0.005)}
        />
    );
};



function Intro({ start, set }) {
    const [vec] = useState(() => new Vector3())
    useEffect(() => setTimeout(() => set(true), 500), [])
    return useFrame((state) => {
        if (start) {
            state.camera.position.lerp(vec.set(state.mouse.x * 5, 3 + state.mouse.y * 2, 14), 0.05)
            state.camera.lookAt(0, 0, 0)
        }
    })
}

//////////////////////////////////////////////7





function Model({ mouse, ...props }) {
    const { width, height } = useThree().size
/*     const gltf = useLoader(GLTFLoader, diamondUrl)
 */    const gltf = useGLTF(diamondUrl, false);

    const model = useMemo(() => gltf.scene.clone(), [0])

    model.position.x = 0;
    model.position.y = -0.1;
    model.position.z = -0.0;
    /*   model.scale.x = 3;
      model.scale.y = 3;
      model.scale.z = 3; */



    useFrame(({ gl, scene, camera }) => {
        const clock = new THREE.Clock()
        const elapsedTime = clock.getElapsedTime()
        model.rotation.y += (mouse.current[0] / (width / 3.5) - model.rotation.y) * 0.01
        model.rotation.x += (mouse.current[1] / (height / 3.5) - model.rotation.x) * 0.01
        if (model.rotation.x <= 0) {

            return (model.rotation.x = 0)
        }
        /*     if(elapsedTime>15000) {
             
              return ( model.rotation.y= 0)
            }
   */


        gl.autoClear = false

/*         model.rotation.y += 0.008 * elapsedTime
 */        gl.render(scene, camera)

    })

    return <primitive object={model} {...props} />
}
/////////////////////////////////////////////////////////////







const Scene = () => {

    // Set receiveShadow on any mesh that should be in shadow,
    // and castShadow on any mesh that should create a shadow.
    return (
        <group>

            <Plane
                receiveShadow
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, -2.5, 0]}
                args={[5, 5]}
            >
                <meshStandardMaterial attach="material" color="white" />
            </Plane>
        </group>
    );
};











//--------------ThreeSection------------------

const Three1 = ({
    DlightBg,
    id,
    DimgStart,
    DtopLine,
    DlightText,
    DheadLine,
    DdarkText,
    Ddescription,
    DbuttonLabel,
    Dimg,
    Dalt,
    Dprimary,
    Ddark,
    Ddark2 }) => {

    const [clicked, setClicked] = useState(false)
    const [ready, setReady] = useState(false)
    const store = { clicked, setClicked, ready, setReady }
    const mouse = useRef([0, 0])


    const sectionRef = useRef(null);

    const intersection = useIntersection(sectionRef, {
        root: null,
        rootMargin: "0px",
        threshold: 0.8,
    });


    const fadeIn3 = element => {
        gsap.to(element, 1, {
            opacity: 0,
            y: -50,
            ease: "power4.out",
            stagger: {
                amount: 0.3
            }
        })
    };

    const fadeOut3 = element => {
        gsap.to(element, 1.2, {
            opacity: 1,
            y: -30,
            ease: "power4.out",

        });
    };

    intersection && intersection.intersectionRatio < 0.8
        ? fadeOut3(".fadeOut3")
        : fadeIn3(".fadeOut3"); //reached so animate

    const props1 = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 1000, duration: 5000,
        pointerlock: true,
    })

    const props2 = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 3500, duration: 5000,
    })

    const props3 = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 3500,
        duration: 5000,
    })

    const props4 = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },


        delay: 3500,
    })

    const props5 = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 3500,
        duration: 5000,
    })

    const propsP = useSpring({
        delay: 10000,

    })
    const [showPlane, set] = useState(true)

    return (
        <>
            <DInfoContainer onMouseMove={e => (mouse.current = [e.clientX - window.innerWidth / 2, e.clientY - window.innerHeight / 2])} DlightBg={DlightBg} id={id}>
                <DInfoWrappper>
                    <DInfoRow DimgStart={DimgStart}>
                        <DColumn1>
                            <DTextWrapper  >

                                <animated.div style={props1}>          <DTopLine style={props3}>{DtopLine}</DTopLine></animated.div>
                                <animated.div style={props2}>       <Heading style={props3} DlightText={DlightText}>{DheadLine}</Heading></animated.div>
                                <animated.div style={props3}>      <DSubtitle style={props3} DdarkText={DdarkText}>{Ddescription}</DSubtitle></animated.div>
                                <animated.div style={props5}>

                                    <InfoRowB>

                                        <D1Column1>
                                            <DBtnWrap>
                                                <BLink to="Los gehts" smooth={true} duration={550} spy={true} exact="true" offset={0}  >         <a to="darumGehts"
                                                    smooth={true}
                                                    duration={550}
                                                    spy={true}
                                                    exact="true"
                                                    offset={0} class="neon-button">Los gehts</a> </BLink >
                                            </DBtnWrap>
                                        </D1Column1>

                                        <D1Column2>

                                            <DBtnWrap to="Kontakt"
                                                    smooth={true}
                                                    duration={550}
                                                    spy={true}
                                                    exact="true"
                                                    offset={0} >
                                                  <a class="neon-button">Kontakt</a>

                                            </DBtnWrap>

                                        </D1Column2>
                                    </InfoRowB>

                                    {/*    <DButton to="home"
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        Dprimary={Dprimary ? 1 : 0}
                                        Ddark={Ddark ? 1 : 0}
                                        Ddark2={Ddark2 ? 1 : 0}
                                    >{DbuttonLabel}</DButton> */}
                                </animated.div>
                            </DTextWrapper>
                        </DColumn1>

                        <DColumn2>
                            <DImgWrap >
                                <animated.div style={props4}>  {/*  <TD /> */}
                                    <Canvas
                                        shadowMap
                                        camera={{ fov: 50, position: [0, 0, 7] }}
                                        onMouseMove={e => (mouse.current = [e.clientX - useFullscreen / 2, e.clientY - useFullscreen / 2])}>
                                        <ambientLight intensity={0.8} color="white" castShadow
                                        /> <spotLight intensity={0.1} position={[50, 50, 50]} angle={0.2} penumbra={1} castShadow />
                                        <directionalLight position={[10, 10, 5]} intensity={0.8} color="white" castShadow
                                            shadow-mapSize-height={512}
                                            shadow-mapSize-width={512} shadowCameraNear={0.001}
                                            shadowCameraFar={1000} />
                                       <Suspense fallback={null}>

                                            <Model castShadow receiveShadow mouse={mouse} />

                                        </Suspense>
                                    </Canvas>   </animated.div>
                            </DImgWrap>
                        </DColumn2>
                    </DInfoRow>
                </DInfoWrappper>
            </DInfoContainer>
        </>
    );
}


export default Three1
