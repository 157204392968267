import React from 'react'
import {
    InfoContainerK,InfoContainerN,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap, Schrift3,Schrift4,SchriftContainer4,Schrift5,Schrift6,
    Img2, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB,Schrift2, SchriftContainer2, Schrift, SchriftContainer, Column2N, InfoContainerNB, InfoContainerNBP, Schrift8, Schrift9, D1ColumnLA, Schrift7
} from './Hero3DElement';
import logo from './galerielaing.png';
import profilhack from './MarkusLuepertz1001.jpg';
import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";

import ARN from '../ARSection/arnuhr';
import { NavLogo } from '../Navbar/NavbarElements';
import HackDame from '../ARSection/ARHackDame';
import HackDameklein from '../ARSection/ARKleineDame';
import hackdameklein from '../ARSection/ARKleineDame';
import HackKreuz from '../ARSection/ARKreuz';
import Luepertz1 from '../ARSection/ARluepertz';

const LuepertzAR = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,toggleHome }) => {
    return (
        <>
 {/*        <InfoContainerNB >
        <NavLogo > <Img2 class="flex" src={logo} alt="Logo" /> </NavLogo>
        </InfoContainerNB> */}
        <SchriftContainer4>
                    
                        <Schrift6 >Markus Lüpertz</Schrift6>   
    
        <InfoContainerNBP >
        <NavLogo /* to="/" onClick={toggleHome} */> <Img2 class="flex" src={profilhack} alt="Logo" /> </NavLogo>
        
        </InfoContainerNBP>




        <Schrift8 >Skulptur - Malerei - Zeichnung

</Schrift8>    
{/* <Schrift8 >
Geboren 1941 in Liberec
</Schrift8>
<Schrift8 >
lebt und arbeitet in Berlin, Düsseldorf und Karlsruhe

</Schrift8>    */}


{/*         <Schrift9 >Markus Lüpertz (*25. April 1941) gehört zu den bekanntesten deutschen Künstlern der Gegenwart. Sein Werk kann in großen Teilen als eine Hymne an die Antike verstanden werden, sein Formenrepertoire wendet sich jedoch gegen jede klassische Auffassung.</Schrift9>    
 */}
{/*                         <D1ColumnLA>
           <a href="https://www.galerie-laing.com/klaus-hack/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingk">mehr Infos</a> 
</D1ColumnLA>
 */}
                        <Schrift5 >{/* neue Möglichkeiten der Betrachtung  */}</Schrift5>                          
                        </SchriftContainer4>
         













            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}



                        <Column2N>
                        
                        <SchriftContainer>
                        <Schrift7 >Augmented Reality Anleitung </Schrift7> 
 <Schrift3 >Mit dem Handy je nach Objekt die Wand oder den Boden der Wahl anvisieren, den "View in AR" Button betätigen,
  mit dem Handy die Fläche abscannen. Sobald das Objekt "aufspringt" und ein grauer Rahmen um das Objekt erscheint, 
  kann man es an die gewünschte Stelle positionieren.</Schrift3>                       
 </SchriftContainer>

                        <SchriftContainer2>
                           {/*  <Schrift2>Künstler: Klaus Hack</Schrift2> */}
                            <Schrift2>Titel: Kopf des Ganymed 1995</Schrift2>                          
                        <Schrift2 >Material: Bronze, bemalt</Schrift2>  
                        <Schrift2>Maße/Gewicht: 63cm x 46cm x 40cm, 40 kg</Schrift2> 
                        <Schrift2>Auflage: 6, Guss 6/6</Schrift2> 
                                             
                        </SchriftContainer2>
                        <SchriftContainer2>
                                            
                        </SchriftContainer2>
                        <Luepertz1/>
                   


               
                        <D1ColumnLA>
           <a href="https://www.galerie-laing.com/kontakt/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingko">Kontakt</a> 
</D1ColumnLA>




                        </Column2N>
                

            </InfoContainerN>
        </>
    );
};

export default LuepertzAR;


















