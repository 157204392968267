export const homeObjOne = {
    id: "about",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "3D Experiense",
    headLine: "Discover with 3D",
    description: "Lorem Ipsum domi en tolumn tan sag. Ipsum domi en tolumn tan sag.Ipsum domi en tolumn tan sag.Ipsum domi en tolumn tan sag.Ipsum domi en tolumn tan sag.",
    buttonLabel: "Get started",
    imgStart: false,
    img: require('../../images/environment.svg').default,
    alt: "Car",
    dark: true,
    primary: true,
    darkText: false,
};

export const homeObjTwo = {
    id: "discover",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Discover the Word with 3D",
    headLine: "Augmented with 3D",
    description: "Lorem Ipsum domi en tolumn tan sag. Ipsum domi en tolumn tan sag.Ipsum domi en tolumn tan sag.Ipsum domi en tolumn tan sag.Ipsum domi en tolumn tan sag.",
    buttonLabel: "Learn More",
    imgStart: true,
    img: require('../../images/vrchat.svg').default,
    alt: "vr",
    dark: false,
    primary: false,
    darkText: true,
};

export const homeObjThree = {
    id: "signup",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "3D Experiense",
    headLine: "Virtual 3D",
    description: "Lorem Ipsum domi en tolumn tan sag. Ipsum domi en tolumn tan sag.Ipsum domi en tolumn tan sag.Ipsum domi en tolumn tan sag.Ipsum domi en tolumn tan sag.",
    buttonLabel: "Start Now",
    imgStart: false,
    img: require('../../images/virtualreality.svg').default,
    alt: "Car",
    dark: false,
    primary: false,
    darkText: true,
};

export const Three1Data = {
    id: "home",
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DtopLine: "The Future is now",
    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    /*     DheadLine: "Entdecke für dich den nutzen und die Innovativen Möglichkeiten unser 3D-Scans", */
    DheadLine: " Mit der Digitalisierung von Kunst & Kultur neue Wege der Visualisierung & Interaktion gehen",
    
    Ddescription: "Wir digitalisieren Ihre Objekte und bringen sie zur Anwendung.",
    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};



export const Three2Data = {
    id: "nutzen",
    DlightBg: false,
    D2lightText: true,
    D2lightTextDesc: true,
    D2topLine: "Darum geht's",
    D2KtopLine: "Kunst",
    D2BtopLine: "Bildung",
    D2WtopLine: "Wissenschaft",

    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "Die  Vorteile von 3D-Scans FÜR SICH nutzen",
    DAheadLine: "3öglich mit Objekten Zeit und Orts unabhänig zu Interagieren.",
    DBheadLine: "3D ScZeit und Orts unabhänig zu Interagieren.",
    DCheadLine: " machen es möglich mit Objekten Zeit und Orts unabhänig zu Interagieren.",
    D2Adescription: "• 3D-Scans ermöglichen die dreidimensionale Betrachtung eines Objektes.",
    D2Bdescription: "• Objekte können so unabhängig von Zeit und Ort studiert werden. ",
    D2Cdescription: "• Mit Augmented Reality können Objekte wieder in den Raum platziert werden. ",
    D2Ddescription: "• Virtuel Reality ermöglicht ein eintauchen Virtuelle Welten  ",
    D2Edescription: "• Virtuel Reality ermöglicht ein eintauchen Virtuelle Welten  ",
    D2Fdescription: "• Virtuel Reality ermöglicht ein eintauchen Virtuelle Welten  ",

    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};

/* einzutauchen werden Objekten wieder Zeit und Orts unabhänig erfahrbar. . */

export const Three3Data = {
    id: "Vorteile",
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DtopLine: "Vorteile von 3D-Scans kurz & knap",
    DtopLine2: "Vorteile für dein Bereich",
    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "Mit 3D-Modellen Das Maximum an Informationsgehalt und Flexibilität nutzen",
    Ddescription: "Mit der Digitalisierung von Objekten entstehen nie da gewesene Wege der Interaktion. ",
    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,


    D2Gdescription: "• Zeitersparnis durch wegfall von Anfahrtswegen.",
    D2Hdescription: "• Überbrückung ferner Dsitanzen",
    D2Idescription: "• Maximierung des Informationsgehalts dank eines 3D-Models  ",


};

export const Three4Data = {
    id: "ar",
    DlightBg: true,
    DlightText: true,
    DlightTextDesc: true,
    DtopLine: "The Future is now",
    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "Entdecke die Möglichkeiten von Mixed Reality",
    Ddescription: "Mit der Digitalisierung von Objekten neue Wege der Interaktion gehen. ",
    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};


export const Three5Data = {
    id: "Beispiel",
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DtopLine: "Maximale Möglichkeit der Betrachtung",
    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "Ein Bild sagt mehr als 1000 Worte ein 3D-Scan sagt mehr als 1000 Bilder",
    Ddescription: "Mit der Digitalisierung von Objekten entstehen nie da gewesene Wege der Interaktion. ",
    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};

export const Three6Data = {
    id: "Lösungen",
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DtopLine: "The Future is now",
    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "Entdecke die Möglichkeiten von 3D-Scans",
    Ddescription: "Mit der Digitalisierung von Objekten entstehen nie da gewesene Wege der Interaktion. ",
    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};


export const Three7Data = {
    id: "Los gehts",
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DtopLine: "Wir bieten Innovative 3D-Lösungen für Ihre Anwendung & Endgerät",
    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "Vom 3D-Scan bis zu Ihrer Wunsch Implementierung. Wir kümmern uns um alles",
    Ddescription: "3D Darstellungen ermöglichen endlich eine Komplettbetrachtung des Objektes. Vielfältige Möglichkeiten der Implementierung, Interaktion und Informationsvermittlung lassen keine Wünsche mehr offen. Mit dem Handy kann z.B. dank Augmented Reality (AR) das Objekt direkt in die gewünschte Umgebung eingefügt werden. Probieren Sie es direkt aus und klicken Sie mit dem Handy auf den View in AR Button",
    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};

export const Kopf1000D = {
    id: "Kopf1000",
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DtopLine: "Wir bieten Innovative 3D-Lösungen für deine Anwendung & dein Endgerät",



    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "Horst Antes",
    Ddescription: "(geb. 1936 in Heppenheim)",
    DheadLine2: "Kopf 1000",
    Ddescription2: "Skulptur aus Eisen mit Rostpatina von 1977,",
    Ddescription3: "signiert und nummeriert,",
    Ddescription4: "45 x 41 x 14 cm.",
    Ddescription5: "Auflage: 1000 Exemplare.",

    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};



export const WallData = {
    id: "Wall",
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DtopLine: "Wir bieten Innovative 3D-Lösungen für deine Anwendung & dein Endgerät",
    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "Mit dem Handy oder Tablet bequem Kunst an den eigenen vier Wänden vorab zu betrachten ist nun möglich",
    Ddescription: "Auch bei Bildern bieten sich dank der Digitalisierung zahlreiche Möglichkeiten. Die Datei Ihres Bildes genügt uns, um es z.B. per Augmented Reality und dem Handy bequem vorab an der Wand zu betrachten. Hochauflösende 3D-Scans Ihrer Bilder und Rahmen sind ebenfalls möglich.",
    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};



export const ARTJAN = {
    id: "Wall2",
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DtopLine: "Wir bieten Innovative 3D-Lösungen für deine Anwendung & dein Endgerät",
    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "Jan Hagmann 2021",
    Ddescription: "Auch bei Bildern bieten sich dank der Digitalisierung zahlreiche Möglichkeiten. Die Datei Ihres Bildes genügt uns, um es z.B. per Augmented Reality und dem Handy bequem vorab an der Wand zu betrachten. Hochauflösende 3D-Scans Ihrer Bilder und Rahmen sind ebenfalls möglich.",
    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};


export const ART = {
    id: "Service",
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DtopLine: "Profitieren auch Sie von der Digitalisieren Ihrer Kunst und Kulturobjekte",
    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "Profitieren auch Sie von der Digitalisierung Ihrer Kunst und Kulturobjekte",
    Ddescription: "Mit Ihnen zusammen Entwickeln wir auf Sie zugeschnittene sinnstiftende Endanwenderlösungen. Von der Digitalisierung einzelner Objekte bis hin zu ganzen Sammlungen und Magazinen bieten wir weitreichende Möglichkeiten zur mehrwertstiftenden Integration in Ihre Online- sowie Offlinepräsenzen. Erreichen Sie durch uns mehr Menschen, sparen Sie Zeit bei alltäglichen Abläufen und vieles mehr. Verschiedene Förderprogramme des Bundes zur Digitalisierung können ebenfalls für Sie infrage kommen.",
    DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};



export const ContactFormData = {
    id: "Kontakt",
    
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DTopLineF:  "More Infos and Examples coming soon!",
    DTopLineF2: "Für ein Beratungsgespräch nehmen Sie gerne Kontakt zu uns auf.",


    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "More Infos and Examples coming soon!",
/*     Ddescription: "Wir freuen uns, dass Sie mit uns Kontakt aufnehmen wollen",
 */   
Ddescription: "oder per Formular",
Ddescription1: "Ich stimme der Datenschutzvereinbarung zu.",
Ddescription2: "",



     DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};



export const Dargon = {
    id: "Dragon",
    
    DlightBg: false,
    DlightText: true,
    DlightTextDesc: true,
    DTopLineF: "",
    DTopLineF2: "",


    /*     DheadLine:"Discover the opportunities of Mixed Reality", */
    DheadLine: "",
/*     Ddescription: "Wir freuen uns, dass Sie mit uns Kontakt aufnehmen wollen",
 */   
Ddescription: "",
Ddescription1: "Ich stimme der Datenschutzvereinbarung zu.",
Ddescription2: "",



     DbuttonLabel: "Start Now",
    DimgStart: false,
    Dimg: require('../../images/virtualreality.svg').default,
    Dalt: "Car",
    Ddark: true,
    Dprimary: true,
    DdarkText: false,
};
