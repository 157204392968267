import React from 'react'
import {
    InfoContainerK,InfoContainerN,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap, Schrift3,Schrift4,SchriftContainer4,Schrift5,Schrift7,Schrift8,Schrift9,
    Img2, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB,Schrift2, SchriftContainer2, Schrift, SchriftContainer, Column2N, InfoContainerNB, Schrift6, D1ColumnL, D1ColumnLA, InfoContainerNBP
} from './Hero3DElement';
import logo from './galerielaing.png';
import profilnuhr from './gu.png';



import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";

import ARN from '../ARSection/arnuhr';
import { NavLogo } from '../Navbar/NavbarElements';
import ARN2 from '../ARSection/arnuhr2';

import ARJ1 from '../ARSection/jiny1';
import ARJ2 from '../ARSection/jiny2';
import ARJ3 from '../ARSection/jiny3';
import ARJ4 from '../ARSection/jiny4';
import ARGu3 from '../ARSection/gu3';
import ARGu1 from '../ARSection/gu1';
import ARGu2 from '../ARSection/gu2';

const GuAR = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,toggleHome }) => {
    return (
        <>
  {/*       <InfoContainerNB >
        <NavLogo> <Img2 class="flex" src={logo} alt="Logo" /> </NavLogo>
        </InfoContainerNB> */}
        <SchriftContainer4>
                    
                        <Schrift6 >Gu Xiaoping</Schrift6>   
    
        <InfoContainerNBP >
        <NavLogo /* to="/" onClick={toggleHome} */> <Img2 class="flex" src={profilnuhr} alt="Logo" /> </NavLogo>
        
        </InfoContainerNBP>
        <Schrift8 >Maler, Fotograf, Performance-Künstler</Schrift8>    
{/*         <Schrift8 >Geboren 1970 in Xiuyan, Liaoning</Schrift8>
        <Schrift8 >Lebt und arbeitet in Düsseldorf</Schrift8> 



        <Schrift9 >Jiny Lan ist eine wichtige Repräsentantin feministischer Kunst in China, die mit ihren Arbeiten häufig Gesellschaftskritik äußert. Sie ist sowohl in Europa wie Asien beheimatet. „Ich bin in einem kommunistischen Land aufgewachsen, wo die Kunst nur als Dekoration für Politik genutzt wurde. </Schrift9>    
 */}
{/*         <D1ColumnLA>
           <a href="https://www.galerie-laing.com/jiny-lan/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingk">mehr Infos</a> 
</D1ColumnLA>
 */}
                        <Schrift5 >{/* neue Möglichkeiten der Betrachtung  */}</Schrift5>                          
                        </SchriftContainer4>
         




            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}



                        <Column2N>
                        
                        <SchriftContainer>
                        <Schrift7 >Augmented Reality Anleitung </Schrift7> 
 <Schrift3 >Mit dem Handy je nach Objekt die Wand oder den Boden der Wahl anvisieren, den "View in AR" Button betätigen,
  mit dem Handy die Fläche abscannen. Sobald das Objekt "aufspringt" und ein grauer Rahmen um das Objekt erscheint, 
  kann man es an die gewünschte Stelle positionieren.</Schrift3>                       
 </SchriftContainer>


 <SchriftContainer2>
                   <Schrift2>Titel: Walking ink line 201910</Schrift2>                          
  {/*                          <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>   */}
                       <Schrift2>Maße: 93cm x 133cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARGu1/> 



                           <SchriftContainer2>
                      <Schrift2>Titel: Walking ink line 202010</Schrift2>                          
          {/*               <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>   */}
                       <Schrift2>Maße: 150cm x 200cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARGu2/> 


                           <SchriftContainer2>
                       <Schrift2>Titel: Walking ink line 201909</Schrift2>                          
              {/*          <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>   */}
                       <Schrift2>Maße: 93cm x 133cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARGu3/> 






                            <D1ColumnLA>
           <a href="https://www.galerie-laing.com/kontakt/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingko">Kontakt</a> 
</D1ColumnLA>


                        </Column2N>
                

            </InfoContainerN>
        </>
    );
};

export default GuAR;


















