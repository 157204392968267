import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
height: 100vh;
bottom: 0;
left: 0;
right: 0;
top: 0;
/* z-index: 0; */
/* overflow: hidden;
 */
/* background: grey; */
@media screen and (max-width: 680px) {
    
    height: 100%; 
}`;

export const FormWrap = styled.div`
display: flex;
flex-direction: column;
justify-content: center;

`;

export const FormWrapD = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
background-color: grey;
`;

export const Icon = styled(Link)`
margin-left: 32px;
margin-top: 32px;
color: #fff;
font-weight: 700;
font-size: 32px;
`;

export const FormContent = styled.div`

display: flex;
flex-direction: column;
justify-content: center;

`;

export const Form = styled.form`


height: auto;
width: 100%;
/* z-index: 1; */
display: grid;
margin: 0 auto;

border-radius: 4px;
`;

export const FormH1 = styled.h1`
font-size: 40px;
text-align: center;
`;

export const FormLabel = styled.label`
margin-bottom: 8px;
font-size: 14px;
color: #fff;
`;

export const Text1 = styled.span`
text-align: center;

color:#fff;
font-size: 38px;
font-style: bold;
`;

export const Text = styled.span`
text-align: center;
color:#fff;
font-size: 18px;
`;


export const Text2 = styled.span`
text-align: center;
margin-top: 40px;
margin-bottom: 30px;
color:#fff;
font-size: 26px;
`;

export const Text3 = styled.span`
text-align: center;
margin-top: 24px;
color:#fff;
font-size: 24px;
`;