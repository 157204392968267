import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute,SidebarRouteD } from "./SidebarElements";

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon>

                </CloseIcon>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                <SidebarLink to="Los gehts" onClick={toggle}>
                        Los gehts
                    </SidebarLink>
                    <SidebarLink to="Vorteile" onClick={toggle}>
                        Die Vorteile
                    </SidebarLink>
                    <SidebarLink to="discover" onClick={toggle}>
                        Beispiel
                    </SidebarLink>
                    <SidebarLink to="services" onClick={toggle}>
                        Lösungen
                    </SidebarLink>
                    <SidebarLink to="signup" onClick={toggle}>
                        3D-Scan
                    </SidebarLink>
{/*                     <SidebarLink to="signup" onClick={toggle}>
                        NFT Casting
                    </SidebarLink> */}
                    <SidebarLink to="signup" onClick={toggle}>
                        Augmented Reality
                    </SidebarLink> 
                    <SidebarLink to="signup" onClick={toggle}>
                        Virtuel Reality
                    </SidebarLink>
                    <SidebarLink to="signup" onClick={toggle}>
                        Service
                    </SidebarLink>
                    <SidebarLink to="Kontakt" onClick={toggle}>
                        Kontakt
                    </SidebarLink>
                    <SidebarRouteD to ="/Impressum" >
                        Impressum
                        </SidebarRouteD>
                    <SidebarRouteD to ="/Datenschutz" >
                        Datenschutz
                        </SidebarRouteD>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to ="/signin">Sign In</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;