import React, { useState, useRef } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/index';
import Datens from '../components/ImpressumDaten/daten';

const DatenAppKunstImParkPage = () =>{

    const [isOpen, setIsOpen] = useState(false)
  
    const toggle = () => {
      setIsOpen(!isOpen)
    }

    return (
        <>
{/*       <Navbar toggle={toggle} />
 */}             <ScrollToTop/> 
            <Datens />
            <Footer     />
        </>
    )
}

export default DatenAppKunstImParkPage;