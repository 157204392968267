import React from 'react'
import {
    InfoContainerK,InfoContainerN,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap, Schrift3,Schrift4,SchriftContainer4,Schrift5,Schrift7,Schrift8,Schrift9,
    Img2, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB,Schrift2, SchriftContainer2, Schrift, SchriftContainer, Column2N, InfoContainerNB, Schrift6, D1ColumnL, D1ColumnLA, InfoContainerNBP
} from './Hero3DElement';
import logo from './galerielaing.png';
import profilnuhr from './gu.png';



import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";

import { NavLogo } from '../Navbar/NavbarElements';


import ARGu1 from '../ARSection/gu1';

const ARGuAusstellung = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,toggleHome }) => {
    return (
        <>

         




            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}



                        <Column2N>
                        
                        <SchriftContainer>
                        <Schrift7 >Augmented Reality Anleitung </Schrift7> 
 <Schrift3 >Mit dem Handy je nach Objekt die Wand oder den Boden der Wahl anvisieren, den "View in AR" Button betätigen,
  mit dem Handy die Fläche abscannen. Sobald das Objekt "aufspringt" und ein grauer Rahmen um das Objekt erscheint, 
  kann man es an die gewünschte Stelle positionieren.</Schrift3>                       
 </SchriftContainer>


                           <ARGu1/> 
 <SchriftContainer2>
                   <Schrift2>GU XIAOPING</Schrift2>     
                   <Schrift2>Walking ink Line, 2019</Schrift2>                          
                   <Schrift2 >Reispapier, Mineralpigmente</Schrift2>   
                   <Schrift2>93 x 133 cm</Schrift2>                      
                   </SchriftContainer2>
                   <SchriftContainer2>
                      
                   </SchriftContainer2>




 




                            <D1ColumnLA>
           <a href="https://www.galerie-laing.com/kontakt/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingko">Kontakt</a> 
</D1ColumnLA>
<InfoContainerNB >
        <NavLogo /* to="/" onClick={toggleHome} */> <Img2 class="flex" src={logo} alt="Logo" /> </NavLogo>
</InfoContainerNB>

                        </Column2N>
                

            </InfoContainerN>
        </>
    );
};

export default ARGuAusstellung;


















