import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import "./App1.css"


const Fader = ({text}) => {
const [fadeProp, setFadeProp] = useState({
    fade: "fade-out",

});


useEffect(() => {
  const timeout =  setTimeout(() => {
      if(fadeProp.fade === "fade-out"){
          setFadeProp({
              fade:"fade-in"
          })
      } 
  }, 2000);
    return () => clearTimeout(timeout)         
}, [fadeProp])



    return (
        <>
<h1 className={fadeProp.fade}>{text}</h1>
        </>
    )
}

Fader.defaultProps = {
    text: "THE FUTURE IS NOW"
}

Fader.propTypes = {
 text: PropTypes.string,
}

export default Fader