import React from 'react';
import "./Download.css"
/* import { Container, FormContent, FormLabel, FormWrap, Icon, Form, FormH1,FormInput, FormButton, Text,Text1,Text2,Text3, FormWrapD  } from './impressumElements';
 */

const JPG_FILE_URL = "http://localhost:3000/penck.jpg"
const APK_FILE_URL = "https://get.hidrive.com/api/RgWFaHiQ/file/ZraBsx3CDXz8KSvHATFMUj"
 const DatenDownload = () => {

    const downloadFileAtURL=(url)=>{
        const aTag = document.createElement("a")
        aTag.href=url
        aTag.setAttribute("download", "https://get.hidrive.com/api/RgWFaHiQ/file/ZraBsx3CDXz8KSvHATFMUj")
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    };

    return (
        <>
            <div className="DownloadApp">
            <button onClick={()=>{downloadFileAtURL(APK_FILE_URL)}}>Download App</button>
</div>

{/*                 <FormWrapD> */}
                {/*    <Icon to="/">Scivis3D</Icon> */}
{/*                  <FormContent>
                       <Form action="#">
                       <h4>Datenschutzerklärung</h4>

                        </Form>
                   </FormContent> 
                </FormWrapD>
            */}
        </>
    );
};

export default DatenDownload;