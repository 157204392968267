import React from 'react'
import {
    InfoContainerK,InfoContainerN,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap, Schrift3,Schrift4,SchriftContainer4,Schrift5,Schrift7,Schrift8,Schrift9,
    Img2, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB,Schrift2, SchriftContainer2, Schrift, SchriftContainer, Column2N, InfoContainerNB, Schrift6, D1ColumnL, D1ColumnLA, InfoContainerNBP
} from './Hero3DElement';
import logo from './galerielaing.png';
import profilnuhr from './dieternuhr1001.jpg';



import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";

import ARN from '../ARSection/arnuhr';
import { NavLogo } from '../Navbar/NavbarElements';
import HackDame from '../ARSection/ARHackDame';
import HackDameklein from '../ARSection/ARKleineDame';
import ARN2 from '../ARSection/arnuhr2';
import ARN3 from '../ARSection/arnuhr3';
import ARN4 from '../ARSection/arnuhr4';
import ARN5 from '../ARSection/arnuhr5';
import ARN6 from '../ARSection/arnuhr6';
import ARN7 from '../ARSection/arnuhr7';
import ARN8 from '../ARSection/arnuhr8';
import ARN9 from '../ARSection/arnuhr9';
import ARN10 from '../ARSection/arnuhr10';
import ARN11 from '../ARSection/arnuhr11';
import ARN13 from '../ARSection/arnuhr12';
import ARN14 from '../ARSection/arnuhr6';
import ARN15 from '../ARSection/arnuhr13';
import ARN16 from '../ARSection/arnuhr6';
import Test1 from '../ARSection/test1';
import Test2 from '../ARSection/test2';

const Test13 = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,toggleHome }) => {
    return (
        <>
    




            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}



                        <Column2N>
                        
                        <SchriftContainer>
                        <Schrift7 >Augmented Reality Anleitung </Schrift7> 
 <Schrift3 >Mit dem Handy je nach Objekt die Wand oder den Boden der Wahl anvisieren, den "View in AR" Button betätigen,
  mit dem Handy die Fläche abscannen. Sobald das Objekt "aufspringt" und ein grauer Rahmen um das Objekt erscheint, 
  kann man es an die gewünschte Stelle positionieren.</Schrift3>                       
 </SchriftContainer>


 <SchriftContainer2>
                       <Schrift2>Titel: Island Sudurdalur 03</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 65cm x 40cm</Schrift2>                      
                   </SchriftContainer2>


                           <Test1/> 
{/* 
                           <SchriftContainer2>
                       <Schrift2>Titel: Neuseeland South Island West Coast 06</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 300cm x 200cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN3/>  */}


                           <SchriftContainer2>
                       <Schrift2>Titel: Neuseeland South Island West Coast 01</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 243cm x 150cm</Schrift2>                      
                   </SchriftContainer2>


                           <Test2/> 


{/*                            <SchriftContainer2>                      
                       <Schrift2>Titel: Schweiz Berninagruppe 03</Schrift2>                          
                   <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                   <Schrift2>Maße: 243cm x 150cm</Schrift2>                      
                   </SchriftContainer2>
                   
                       <ARN/>





                           <SchriftContainer2>
                       <Schrift2>Titel:Italien Costiera Amalfitana 07</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 200cm x 200cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN5/> 


                           <SchriftContainer2>
                       <Schrift2>Titel: Bolivien Huayna Potosí 05</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 146cm x 90cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN16/> 




                           <SchriftContainer2>
                       <Schrift2>Titel: Neuseeland Pancake Rocks 02</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 146cm x 90cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN7/> 
 */}



{/*                            <SchriftContainer2>
                       <Schrift2>Titel: Neuseeland Pancake Rocks 01</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 146cm x 90cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN8/> 
 */}



{/*                            <SchriftContainer2>
                       <Schrift2>Titel: Island Sudurdalur 03</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 65cm x 40cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN9/> */} 


{/* 
                           <SchriftContainer2>
                       <Schrift2>Titel: Neuseeland Wellsford 01</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 146cm x 90cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN8/> 






                           <SchriftContainer2>
                       <Schrift2>Titel: Indien Periyar 18</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 50cm x 50cm</Schrift2>                      
                   </SchriftContainer2>


                   <ARN2/> 



                           <SchriftContainer2>
                       <Schrift2>Titel: Indien Periyar 20</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 50cm x 50cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN10/> 


                           <SchriftContainer2>
                       <Schrift2>Titel:Indien Periyar 21</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 50cm x 50cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN15/> 




                           <SchriftContainer2>
                       <Schrift2>Titel:Indien Periyar 29</Schrift2>                          
                       <Schrift2 >Technik: Fotografie und digitale Malerei</Schrift2>  
                       <Schrift2>Maße: 50cm x 50cm</Schrift2>                      
                   </SchriftContainer2>


                           <ARN11/> 
 */}






{/* 
                            <D1ColumnLA>
           <a href="https://www.galerie-laing.com/kontakt/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingko">Kontakt</a> 
</D1ColumnLA> */}


                        </Column2N>
                

            </InfoContainerN>
        </>
    );
};

export default Test13;


















