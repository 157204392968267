import React from "react";
import "@google/model-viewer/dist/model-viewer";
import "./styles.css";


function ARWall1() {
    return (


<model-viewer src="Bild.glb"  ar ios-src="bild.usdz"
 ar ar-placement="wall"
 ar-modes="webxr scene-viewer quick-look"
  camera-controls alt="Schwanenbild"
  max-camera-orbit="auto 98deg auto"
  min-camera-orbit="auto 50deg auto"
  camera-orbit="0deg 75deg 2.5m ">
 <button slot="ar-button" id="ar-button">  View in AR</button>
 </model-viewer>


    );
}

export default ARWall1;



