import React from 'react'
import JanAufsteller from '../ARSection/ARjanAufsteller';
import Container1 from '../ARSection/container';
import Containerfix from '../ARSection/containerfix';
import { Container } from '../Signin/SigninElements';
import {
    InfoContainerN,

     Column2N, 
} from './Hero3DElement';




const C1333 = ({
    lightBg,
    id,
  
    dark2,toggleHome }) => {
    return (
        <>


            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}


                        <Column2N>
        
                     <Container1/> 
                     {/*    <Containerfix/> */}

                        </Column2N>
                

            </InfoContainerN>
        </>
    );
};

export default C1333;


















