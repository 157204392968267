import styled from "styled-components";
import {Link} from "react-scroll";

export const Button = styled(Link)`
border-radius: 50px;
background: ${({primary}) => (primary ? "#1726ff" : "#010606")};
white-space: nowrap;
padding: ${({big}) => (big ? "14px 48px" : "12px 30px")};
color: ${({dark}) => (dark ? "#010606" : "#fff")};
font-size: ${({fontbig}) => (fontbig ? "20px" : "16px")};
outline: none;
border: none;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
transition: all 0.2 ease-in-out;
color: white;
&:hover {
    transition: all 0.2 ease-in-out;
    background: ${({primary}) => (primary ? "#fff" : "#1726ff" )};
    color: ${({primary}) => (primary ? "black" : "white" )};
    
}`;


export const DButton = styled(Link)`
border-radius: 50px;
background: ${({Dprimary}) => (Dprimary ? "#1726ff" : "#010606")};
white-space: nowrap;
padding: ${({big}) => (big ? "14px 48px" : "12px 30px")};
color: ${({Ddark}) => (Ddark ? "#010606" : "#fff")};
font-size: ${({fontbig}) => (fontbig ? "20px" : "16px")};
outline: none;
border: none;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
transition: all 0.2 ease-in-out;
color: white;
&:hover {
    transition: all 0.2 ease-in-out;
    background: ${({Dprimary}) => (Dprimary ? " #30F063" : "#0011ff" )};
    color: ${({Dprimary}) => (Dprimary ? "black" : " #30F063" )};
    box-shadow: 0 1.9rem 3.8rem rgba(0,0,0,0.3), 0 1.5rem 1.2rem rgba(0,0,0,0.22);
    transform: translate(0, -0.2rem);
    
}`;


