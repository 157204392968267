import React from 'react'
import {
    InfoContainerK,InfoContainerN,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2K,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap, Schrift3,Schrift4,SchriftContainer4,Schrift5,Schrift6,
    Img2, InfoRowB, D1Column1, D1Column2, TopLineT, SubWrap, BorderWrap, InfoRowA,InfoRowAB,Schrift2, SchriftContainer2, Schrift, SchriftContainer, Column2N, InfoContainerNB, InfoContainerNBP, Schrift8, Schrift9, D1ColumnLA, Schrift7
} from './Hero3DElement';
import logo from './galerielaing.png';
import profilhack from './klaushack1001.jpg';
import AR2 from '../ARSection/ARSection2';

/* import Img2 from "../../images/vrchat.svg"
import Img3 from "../../images/vrchat.svg" */
import {
    DInfoRow,
    DBtnWrap,

    BLink, DInfoRowB,
} from "../InfoSection/InfoElements";

import ARN from '../ARSection/arnuhr';
import { NavLogo } from '../Navbar/NavbarElements';
import HackDame from '../ARSection/ARHackDame';
import HackDameklein from '../ARSection/ARKleineDame';
import hackdameklein from '../ARSection/ARKleineDame';
import HackKreuz from '../ARSection/ARKreuz';

const HackAR = ({
    lightBg,
    id,
    imgStart,
    DtopLine,
    DheadLine, DheadLine2,
    darkText,
    Ddescription, Ddescription2, Ddescription3, Ddescription4, Ddescription5,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,toggleHome }) => {
    return (
        <>
  {/*       <InfoContainerNB >
        <NavLogo > <Img2 class="flex" src={logo} alt="Logo" /> </NavLogo>
        </InfoContainerNB> */}
        <SchriftContainer4>
                    
                        <Schrift6 >Klaus Hack</Schrift6>   
    
        <InfoContainerNBP >
        <NavLogo /* to="/" onClick={toggleHome} */> <Img2 class="flex" src={profilhack} alt="Logo" /> </NavLogo>
        
        </InfoContainerNBP>




        <Schrift8 >Skulpturen – Reliefs – Holzschnitte

</Schrift8>    
{/* <Schrift8 >
Geboren 1966 in Bayreuth
</Schrift8>
<Schrift8 >
studierte an den Kunstakademien in Nürnberg und Berlin</Schrift8>   
 */}

{/*         <Schrift9 >Klaus Hack wurde vielfach ausgezeichnet, seine Arbeiten befinden sich in renommierten Sammlungen und Museen wie der Kunsthalle Mannheim, der Bayerischen Staatsgemäldesammlung in München oder dem Museum Kunstpalast in Düsseldorf.</Schrift9>    
 */}
{/*                         <D1ColumnLA>
           <a href="https://www.galerie-laing.com/klaus-hack/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingk">mehr Infos</a> 
</D1ColumnLA>
 */}
                        <Schrift5 >{/* neue Möglichkeiten der Betrachtung  */}</Schrift5>                          
                        </SchriftContainer4>
         













            <InfoContainerN lightBg={lightBg} id={id}>

      {/*       <TopLine >{DtopLine}</TopLine>
            <Heading  >{DheadLine}</Heading> */}



                        <Column2N>
                        
                        <SchriftContainer>
                        <Schrift7 >Augmented Reality Anleitung </Schrift7> 
 <Schrift3 >Mit dem Handy je nach Objekt die Wand oder den Boden der Wahl anvisieren, den "View in AR" Button betätigen,
  mit dem Handy die Fläche abscannen. Sobald das Objekt "aufspringt" und ein grauer Rahmen um das Objekt erscheint, 
  kann man es an die gewünschte Stelle positionieren.</Schrift3>                       
 </SchriftContainer>

                        <SchriftContainer2>
                           {/*  <Schrift2>Künstler: Klaus Hack</Schrift2> */}
                            <Schrift2>Titel: Frauen-Figur</Schrift2>                          
                        <Schrift2 >Material: Kirsche, schwarz-weiß</Schrift2>  
                        <Schrift2>Maße: 202cm x 46cm x 47cm</Schrift2>                      
                        </SchriftContainer2>
                        <SchriftContainer2>
                                            
                        </SchriftContainer2>
                        <HackDame/>
                   


                        <SchriftContainer2>
                 {/*            <Schrift2>Künstler: Klaus Hack</Schrift2> */}
                            <Schrift2>Titel: Figur 2009</Schrift2>                          
                        <Schrift2 >Material: Eiche, weiß gefasst</Schrift2>  
                        <Schrift2>Maße: 69cm x 28cm x 23,5cm</Schrift2>                      
                        </SchriftContainer2>
                        <SchriftContainer2>
                                            
                        </SchriftContainer2>
                        <HackDameklein/>

                        <SchriftContainer2>
                 {/*            <Schrift2>Künstler: Klaus Hack</Schrift2> */}
                            <Schrift2>Titel: Kreuz-Altar 2020/2021</Schrift2>                          
                        <Schrift2 >Material: Kiefer, teilweise weiß gefasst</Schrift2>  
                        <Schrift2>Maße: 108,5cm x 52,5cm x 23,5cm</Schrift2>                      
                        </SchriftContainer2>
                        <SchriftContainer2>
                                            
                        </SchriftContainer2>
                        <HackKreuz/>

               
                        <D1ColumnLA>
           <a href="https://www.galerie-laing.com/kontakt/"
   smooth={true}
duration={500}
spy={true}
exact="true"
offset={120} class="buttonlaingko">Kontakt</a> 
</D1ColumnLA>




                        </Column2N>
                

            </InfoContainerN>
        </>
    );
};

export default HackAR;


















