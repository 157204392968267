import React from 'react'
import WallJan from '../components/Hero3DSection/artjan';
import WallJan2 from '../components/Hero3DSection/artjan2';
import ScrollToTop from '../components/ScrollToTop';
import SignIn from '../components/Signin'

const SigninPage = () => {
    return (
        <>
        <ScrollToTop/>
        
   <SignIn/>


        </>
    )
}

export default SigninPage;