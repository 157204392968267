import React, { useRef, useEffect, useState, Suspense } from "react";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import { Html, useProgress, useGLTF } from "drei";
import { a, useTransition } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import "./App.scss";
import { useIntersection } from "react-use";
import gsap from "gsap";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

/* import { Column1, Column2, ImgWrap, InfoContainer, InfoRow, InfoWrappper } from "../Hero3DSection/Hero3DElement"; */
import AR1 from "../ARSection/ARSection";
import { Button, DButton } from '../ButtonElement'
import {
    InfoContainer,
    InfoRow,
    InfoWrappper,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img,
} from './InfoDElements';
//--------------LOADER------------------
import Img1 from "../../images/vrchat.svg"
import {
    DInfoContainer, DInfoRow, DInfoWrappper, DColumn1,
    DColumn2,
    DTextWrapper,
    DTopLine,
    DHeading,
    DSubtitle,
    DBtnWrap,
    DImgWrap,
    DImg,
} from "../InfoSection/InfoElements";

function Loader() {
    const { active, progress } = useProgress();
    const transition = useTransition(active, {
        from: { opacity: 1, progress: 0 },
        leave: { opacity: 0 },
        update: { progress },
    });
    return transition(
        ({ progress, opacity }, active) =>
            active && (
                <a.div className='loading' style={{ opacity }}>
                    <div className='loading-bar-container'>
                        <a.div className='loading-bar' style={{ width: progress }}></a.div>
                    </div>
                </a.div>
            )
    );
}

//--------------LOAD MODEL------------------


function Model({ url }) {
    const gltf = useGLTF(url, true);
    return <primitive object={gltf.scene} dispose={null} />;
}


//--------------LIGHT------------------


const Lights = () => {
    return (
        <>
            {/* Ambient Light illuminates lights for all objects */}
            <ambientLight intensity={1.3} color="white" />
            {/* Diretion light */}
            <directionalLight position={[10, 10, 5]} intensity={0.3} color="white" />
            <directionalLight
                castShadow
                position={[0, 10, 0]}
                intensity={1.2}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={50}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
                color="white"
            />
            {/* Spotlight Large overhead light */}
            <spotLight intensity={1} position={[1000, 0, 0]} castShadow color="white" />
        </>
    );
};


//--------------HTMLContent------------------


const HTMLContent = ({
    children,
    modelPath,
    position,
}) => {
    const ref = useRef();
    useFrame(() => (ref.current.rotation.y += 0.005));
    const [refItem, inView] = useInView({
        threshold: 0,
    });

    return (

        <group position={[0, position, 0]}>
            <mesh ref={ref} position={[-20, 5, 0]} scale={[30, 30, 30]}>
                <Model url={modelPath} />
            </mesh>
            <Html >
                <div ref={refItem} className='container'>
                    <h1 className='title'>{children}</h1>
                </div>
            </Html>
        </group>

    );
};



const CameraController = () => {
    const { camera, gl } = useThree();
    useEffect(
      () => {
        const controls = new OrbitControls(camera, gl.domElement);
  
/*         controls.minDistance = 0;
        controls.maxDistance = 20; */
        return () => {
          controls.dispose();
        };
      },
      [camera, gl]
    );
    return null;
  };
//--------------ThreeSection------------------

const Three7 = ({
    DlightBg,
    id,
    DimgStart,
    DtopLine,
    DlightText,
    DheadLine,
    DdarkText,
    Ddescription,
    DbuttonLabel,
    Dimg,
    Dalt,
    Dprimary,
    Ddark,
    Ddark2 }) => {

    const sectionRef = useRef(null);

    const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.9,
    });


    const fadeIn1 = element => {
        gsap.to(element, 1, {
            opacity: 1,
            y: -50,
            ease: "power4.out",
            stagger: {
                amount: 0.7
            }
        })
    };

    const fadeOut1 = element => {
        gsap.to(element, 1.2, {
            opacity: 0,
            y: -30,
            ease: "power4.out",
       
        });
    };

    intersection && intersection.intersectionRatio < 0.9
    ? fadeOut1(".fadeIn1") 
    : fadeIn1(".fadeIn1"); //reached so animate



    return (
        <>
            <DInfoContainer DlightBg={DlightBg} id={id}>
                <DInfoWrappper>
                    <DInfoRow DimgStart={DimgStart}>
                        <DColumn1>
                            <DImgWrap>
                                <Canvas camera={{ position: [0, 0, 120], fov: 70 }}>
                                    {/* Lights Component */}
                                    <Lights />
                                    <CameraController />
                                    <Suspense fallback={null}>
                                        <HTMLContent 
                                            /*bgColor='#f15946'*/
                                            modelPath='/Flaschenhalter.glb'
                                            position={0}>
                                            {/* <span>Meet the new </span>
                                            <span>shopping experience </span>
                                            <span>for online chairs</span> */}
                                        </HTMLContent>
                                    </Suspense>
                                </Canvas>
                            </DImgWrap>
                        </DColumn1>
                        <DColumn2>
                            <DTextWrapper  ref={sectionRef}>
                                <DTopLine  >{DtopLine}</DTopLine>
                                <DHeading  DlightText={DlightText}>{DheadLine}</DHeading>
                                <DSubtitle  DdarkText={DdarkText}>{Ddescription}</DSubtitle>
                                <DBtnWrap >
                                    <DButton  to="home"
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        Dprimary={Dprimary ? 1 : 0}
                                        Ddark={Ddark ? 1 : 0}
                                        Ddark2={Ddark2 ? 1 : 0}
                                    >{DbuttonLabel}</DButton>
                                </DBtnWrap>
                            </DTextWrapper>
                        </DColumn2>
                    </DInfoRow>
                </DInfoWrappper>
            </DInfoContainer>
        </>
    );
}



export default Three7
